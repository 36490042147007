/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { CssGrid } from '../../configCSS'
import {
  Template,
  Stepper,
  DayLine,
  ActionButton,
  Loader,
  ModalComponent,
  AllowedToChange,
} from '../../components'
import isAllowed from '../../components/utils/profilAuthorization'
// import scrollTo from '../../components/utils/scrollTo/scrollTo'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const newLine = {
  id: 1,
  id_title: null,
  version: 1,
  id_accomodation: null,
  id_pension: null,
  id_room: [],
  id_room_opt1: [],
  id_room_opt2: [],
  title: '',
  shortTitle: '',
  start: '',
  end: '',
  image: '',
  description: '',
  transport: '',
  distance: '',
  day_duration: 1,
  day_activity: '',
  accomodation: '',
  room: [],
  nbRoom: [],
  room_opt1: [],
  nbRoom_opt1: [],
  room_opt2: [],
  nbRoom_opt2: [],
  pension: '',
  guide: '0',
  driver: '0',
  id_accomodation_opt_1: null,
  accomodation_opt_1: null,
  id_accomodation_opt_2: null,
  accomodation_opt_2: null,
  personalized: '0',
  active: '1',
}

class DaysList extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    LOADER: PropTypes.func.isRequired,
    ACTIVE_STEP: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    ID_TRIP_SELECT: PropTypes.func.isRequired,
    GET_LIST_CIRCUIT: PropTypes.func.isRequired,
    tripAllDaysAccomodations: PropTypes.array,
    GET_TRIP_CONTENT: PropTypes.func.isRequired,
    GET_TRIP_ALLDAYS: PropTypes.func.isRequired,
    GET_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_ALLDAYS_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT2: PropTypes.func.isRequired,
    GET_TRIP_PENSION: PropTypes.func.isRequired,
    GET_TRIP_ROOM: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT2: PropTypes.func.isRequired,
    PUT_TRIP_CONTENT: PropTypes.func.isRequired,
    PUT_DAY_CONTENT: PropTypes.func.isRequired,
    RESET_TRIP_CONTENT: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    DELETE_ROW_PRICE: PropTypes.func.isRequired,
    GET_TRIP_PRICE: PropTypes.func.isRequired,
    RESET_DAY_CONTENT: PropTypes.func.isRequired,
    RESET_PUT_TRIP_PRICE: PropTypes.func.isRequired,
    REORDER_DAYS_RANK: PropTypes.func.isRequired,
    RESET_REORDER_STATUS: PropTypes.func.isRequired,
    GET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    PUT_TRIP_ACCOMODATION_ACTIVITY: PropTypes.func.isRequired,
    putTripContent: PropTypes.object,
    isTripDetailsStepValidated: PropTypes.bool.isRequired,
    idTripSelected: PropTypes.number,
    isNewTrip: PropTypes.bool,
    isDetailModified: PropTypes.bool,
    tripDetails: PropTypes.array,
    tripContent: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    tripPension: PropTypes.array,
    tripRoom: PropTypes.array,
    tripRoomOpt1: PropTypes.array,
    tripRoomOpt2: PropTypes.array,
    tripPrice: PropTypes.array,
    daysList: PropTypes.array,
    accomodationList: PropTypes.array,
    locationList: PropTypes.array,
    deleteRowPrice: PropTypes.object,
    userAccount: PropTypes.object,
    reorderStatus: PropTypes.object,
    tripActivities: PropTypes.object,
  }

  static defaultProps = {
    isLoading: true,
    reorderStatus: {},
    idTripSelected: null,
    isNewTrip: true,
    isDetailModified: false,
    tripDetails: [],
    tripContent: [],
    tripAllDays: [],
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripAllDaysAccomodations: [],
    tripPension: [],
    tripRoom: [],
    tripRoomOpt1: [],
    tripRoomOpt2: [],
    tripPrice: [],
    locationList: [],
    daysList: [],
    accomodationList: [],
    putTripContent: {},
    deleteRowPrice: null,
    userAccount: {},
    tripActivities: {},
  }

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this)
    this.getAllTripInformation = this.getAllTripInformation.bind(this)
    this.getAllTripInformationLoad = this.getAllTripInformationLoad.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.addDayLine = this.addDayLine.bind(this)
    this.deleteDay = this.deleteDay.bind(this)
    this.redirectManagement = this.redirectManagement.bind(this)
    this.updateItems = this.updateItems.bind(this)
    this.checkRequiredInputs = this.checkRequiredInputs.bind(this)
    this.validate = this.validate.bind(this)
    this.saveData = this.saveData.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.createNewVersion = this.createNewVersion.bind(this)
    this.isModalOpenInChildren = this.isModalOpenInChildren.bind(this)
    this.displayAuthorizationWarning = this.displayAuthorizationWarning.bind(this)
    this.DNDContent = this.DNDContent.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      items: [],
      isFristLoading: true,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      navigateToDestination: null,
      isValidated: true,
      isValidatedStruct: true,
      isModalOpen: false,
      isModalDisplayed: false,
      isRedirected: false,
      isCreatedNewVersion: false,
      isReordering: false,
    }
  }

  componentDidMount() {
    const {
      history,
      isTripDetailsStepValidated,
      LOADER,
      ACTIVE_STEP,
      isNewTrip,
      tripContent,
    } = this.props
    // check if user is logged


    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // redirect if preview step is not active
    if (!isTripDetailsStepValidated) history.push('trip')
    // Active Loader
    if (!isNewTrip) LOADER(true)
    // define active step for Stepper
    ACTIVE_STEP('daysList')
    // get trip informations
    if (!isNewTrip && !_.isEmpty(tripContent)) {
      LOADER(true)
      this.getAllTripInformationLoad(tripContent)
    } else {
      LOADER(false)
      this.setState({ items: [{ ...newLine }] })
    }
    if (isNewTrip) this.setState({ items: [{ ...newLine }] })


    if (!_.isEmpty(history.location.state) && history.location.state.itemsGoBack) {
      const {
        goBack,
        itemsGoBack,
        idDayLineModified,
        pictureSelected,
      } = history.location.state
      const {
        VALIDATE_STEP,
      } = this.props
      if (goBack === 'day-personalized') {
        const newItems = _.map(itemsGoBack, (o) => {
          let newItem = {}
          if (pictureSelected && o.id === idDayLineModified) {
            newItem = {
              ...o,
              image: pictureSelected.join(),
            }
            VALIDATE_STEP('DaysList', false)

            this.setState({
              isValidated: false,
              isValidatedStruct: false,
            })
          } else {
            newItem = o
          }
          return newItem
        })
        this.setState({
          items: newItems,
          isFristLoading: false,

        })
      }
      LOADER(false)
    // Normal running we get all informations from DB and build drag and drop object
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      token,
      isFristLoading,
      isReordering,
      navigateToDestination,
      userId,
    } = this.state
    const {
      history,
      LOADER,
      tripContent: tripContentOld,
      VALIDATE_STEP,
      ID_TRIP_SELECT,
      RESET_REORDER_STATUS,
      daysList: daysListOld,
      accomodationList: accomodationListOld,
    } = this.props
    const {
      idTripSelected,
      tripContent,
      tripAllDays,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripPension,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      daysList,
      accomodationList,
      locationList,
      tripDetails,
      putTripContent,
      deleteRowPrice,
      reorderStatus,
      GET_TRIP_CONTENT,
      GET_LIST_CIRCUIT,
      GET_TRIP_ACTIVITIES,
      GET_TRIP_DEVIS_FINAL,
      RESET_TRIP_CONTENT,
      RESET_PUT_TRIP_PRICE,
      GET_TRIP_PRICE,
      ROW_TRIP_SELECT,
    } = nextProps
    // check if token is always valid
    if (!_.isEmpty(tripAllDays) && tripAllDays[0].error) {
      localStorage.setItem('isLogged', false)
      alert(tripAllDays[0].error)
      history.push('/')
    }

    // **********************************
    // When this page is a feedback from page 'day' or 'accomodation' or 'gallery', get item created
    // All informations are given in the history.push

    if (!_.isEmpty(history.location.state) && history.location.state.itemsGoBack) {
      const {
        itemsGoBack,
        idDayLineModified,
      } = history.location.state

      // When you create a new day or accomodation in DaysList, you are redirected to the managemant page
      // and of course a new item is created so we check that
      if (daysList !== daysListOld || accomodationList !== accomodationListOld) {
        const {
          idItemCreated,
          field,
        } = history.location.state

        // find the new item
        const itemCreated = field === 'title'
          ? _.filter(daysList, o => o.id_journee === idItemCreated)[0]
          : _.filter(accomodationList, o => o.id_hebergement === idItemCreated)[0]
          // Replace in the global items the new item
        const newItems = _.map(itemsGoBack, (o) => {
          let newItem = {}
          if (o.id === idDayLineModified) {
            newItem = {
              ...o,
              id_title: field === 'title' ? idItemCreated : o.id_title,
              title: field === 'title' ? itemCreated.nom_journee : o.title,
              shortTitle: field === 'title' ? itemCreated.nom_journee_court : o.shortTitle,
              version: field === 'title' ? itemCreated.version : o.version,
              end: field === 'title' ? _.find(locationList, l => l.id_lieu === itemCreated.id_end).lieu : o.end,
              id_end: field === 'title' ? itemCreated.id_end : o.id_end,
              start: field === 'title' ? _.find(locationList, l => l.id_lieu === itemCreated.id_start).lieu : o.start,
              id_start: field === 'title' ? itemCreated.id_end : o.id_start,
              description: field === 'title' ? itemCreated.desc_journee : o.description,
              transport: field === 'title' ? itemCreated.transport : o.transport,
              distance: field === 'title' ? itemCreated.distance : o.distance,
              day_duration: field === 'title' ? itemCreated.day_duration : o.day_duration,
              day_activity: field === 'title' ? itemCreated.day_activity : o.day_activity,
              id_accomodation: field === 'accomodation' ? itemCreated.id_hebergement : o.id_accomodation,
              accomodation: field === 'accomodation' ? itemCreated.nom_hebergement : o.accomodation,
              id_accomodation_opt_1: field === 'title' ? null : o.id_accomodation_opt_1,
              accomodation_opt_1: field === 'title' ? '' : o.accomodation_opt_1,
              id_accomodation_opt_2: field === 'title' ? null : o.id_accomodation_opt_2,
              accomodation_opt_2: field === 'title' ? '' : o.accomodation_opt_2,
              image: field === 'title' ? '' : o.image,
              active: o.active,
              id_room: [],
              room: [],
              nb_room: [],
              id_room_opt1: [],
              room_opt1: [],
              nb_room_opt1: [],
              id_room_opt2: [],
              room_opt2: [],
              nb_room_opt2: [],
              guide: field === 'title' ? '0' : o.guide,
              driver: field === 'title' ? '0' : o.driver,
              personalized: o.active === '1' ? '0' : '1',
            }
          } else {
            newItem = o
          }
          return newItem
        })
        this.setState({
          items: newItems,
          isValidated: false,
          isFristLoading: false,
        })

        LOADER(false)
      }
    // Normal running we get all informations from DB and build drag and drop object
    } else if (tripAllDays && tripAccomodation && tripAccomodationOpt1 && tripAccomodationOpt2 && tripPension && tripRoom && tripRoomOpt1 && tripRoomOpt2 && isFristLoading) {
      // build drag and drop object
      const nbRoom = tripContent[0].nb_chambre ? tripContent[0].nb_chambre.split(',') : []
      const nbRoom_Opt1 = tripContent[0].nb_chambre_opt1 ? tripContent[0].nb_chambre_opt1.split(',') : []
      const nbRoom_Opt2 = tripContent[0].nb_chambre_opt2 ? tripContent[0].nb_chambre_opt2.split(',') : []
      this.setState({
        items: this.DNDContent(tripAllDays, tripAccomodation, tripAccomodationOpt1, tripAccomodationOpt2, tripPension, tripRoom, nbRoom, tripRoomOpt1, nbRoom_Opt1, tripRoomOpt2, nbRoom_Opt2),
        isFristLoading: false,
      })
      LOADER(false)
    }

    // When delete row unvalid step and get again tripContent
    if (deleteRowPrice && deleteRowPrice.success) {
      RESET_PUT_TRIP_PRICE()
      GET_TRIP_PRICE(token, idTripSelected)
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      this.setState({
        isValidated: false,
        isValidatedStruct: false,
        validMsg: 'the day was successfully deleted !',
      })
      this.resetValidMsg()
      this.stopLoaderDelay(2000)
      // unvalidate of step
      VALIDATE_STEP('DaysList', false)
    }

    // if error from server on PUT_TRIP_CONTENT
    if (putTripContent && !putTripContent.success) {
      LOADER(false)
      this.setState({
        validateError: true,
        errorMsg: putTripContent.message,
      })
    }
    // **********************************
    // Validate step
    if (putTripContent && putTripContent.success) {
      const idTour = putTripContent.id
      const {
        isNewId,
        idTrip,
      } = putTripContent

      // validation of step
      VALIDATE_STEP('DaysList', true)

      if (isReordering) {
        if (reorderStatus.success) {
          RESET_REORDER_STATUS()
          GET_TRIP_ACTIVITIES(token, idTour)
          this.setState((prevState) => {
            // Créez une copie de l'état actuel
            const newState = prevState.items.slice();

            // Modifiez les IDs
            newState.forEach((item, index) => {
              item.id = index + 1;
            });

            // Retournez le nouvel état
            return {
              isReordering: false,
              items: newState,
            };
          });
        }
      }

      // reset result of put trip content request
      RESET_TRIP_CONTENT()
      // update content of this trip
      GET_TRIP_CONTENT(idTour)
      // reset tripDevisFinall
      GET_TRIP_DEVIS_FINAL(token, tripDetails[0].id_devis)
      // update trip selected status, is not new now
      ID_TRIP_SELECT(idTrip || idTripSelected, false, true)
      // reset row selected if trip creation or new version
      if (isNewId) {
        GET_LIST_CIRCUIT(token, '2020-01', userId)
        ROW_TRIP_SELECT([])
      }
    }
    if (!_.isEqual(tripContent, tripContentOld)) {
      this.getAllTripInformation(tripContent, true)

      // Go to next page
      if (navigateToDestination) {
        setTimeout(() => {
          history.push(navigateToDestination)
        }, 2200);
      }
    } else {
      // this.stopLoaderDelay(2000)
    }
  }

  componentWillUnmount() {
    const {
      ACTIVE_STEP,
      tripAllDaysAccomodations,
      tripActivities,
      tripAccomodation,
      idTripSelected,
      PUT_TRIP_ACCOMODATION_ACTIVITY,
    } = this.props
    const {
      token,
    } = this.state
    ACTIVE_STEP('')

    // on enregistre les hotels dans les activites
    const dayIndex = tripAllDaysAccomodations.map(objet => ({
      dayIndex: objet.dayIndex,
      id_journee: objet.id_journee,
    }));
    const copiedObject = _.cloneDeep(tripActivities);

    const tripActivitiesFiltered = _.mapValues(copiedObject, array => array.map(({ day_index, id_activity, id_accomodation }) => ({ day_index, id_activity, id_accomodation })));
    const tableauAcco = []
    tableauAcco.push(tripAccomodation.map(objet => (objet.id_hebergement ? objet.id_hebergement : '1')))

    PUT_TRIP_ACCOMODATION_ACTIVITY(token, dayIndex, tripActivitiesFiltered, idTripSelected, tableauAcco[0])
  }

  onDragEnd(result) {
    const {
      items,
    } = this.state
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsSorted = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    this.setState({
      items: itemsSorted,
      isValidated: false,
      isValidatedStruct: false,
    });
  }

  async getAllTripInformation(content, message = false) {
    const { token } = this.state
    const {
      GET_TRIP_ALLDAYS,
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ALLDAYS_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_PENSION,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      GET_TRIP_ACTIVITIES,
      LOADER,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        await GET_TRIP_ALLDAYS(content[0].journee, content[0].id_circuit)
        await GET_TRIP_ACCOMODATION(content[0].hebergement)
        await GET_TRIP_ALLDAYS_ACCOMODATION(content[0].journee, content[0].hebergement)
        await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        await GET_TRIP_PENSION(content[0].pension)
        await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)
        await GET_TRIP_ACTIVITIES(token, content[0].id_circuit)

        LOADER(false)

        if (message) {
          this.setState({
            isValidated: true,
            isValidatedStruct: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

  async getAllTripInformationLoad(content, message = false) {
    const { token } = this.state
    const {
      GET_TRIP_ALLDAYS,
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_PENSION,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      GET_TRIP_ACTIVITIES,
      tripAllDays,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripPension,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      tripActivities,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        if (_.isEmpty(tripAllDays)) await GET_TRIP_ALLDAYS(content[0].journee, content[0].id_circuit)
        if (_.isEmpty(tripAccomodation)) await GET_TRIP_ACCOMODATION(content[0].hebergement)
        if (_.isEmpty(tripAccomodationOpt1)) await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        if (_.isEmpty(tripAccomodationOpt2)) await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        if (_.isEmpty(tripPension)) await GET_TRIP_PENSION(content[0].pension)
        if (_.isEmpty(tripRoom)) await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        if (_.isEmpty(tripRoomOpt1)) await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        if (_.isEmpty(tripRoomOpt2)) await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)
        if (_.isEmpty(tripActivities)) await GET_TRIP_ACTIVITIES(token, content[0].id_circuit)
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

  stopLoaderDelay = (delay) => {
    const {
      LOADER,
    } = this.props
    setTimeout(() => {
      LOADER(false)
    }, delay);
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 2000)
  }

  addDayLine = () => {
    const { items } = this.state
    const oneDayMore = _.clone(items)
    oneDayMore.push({
      ...newLine,
      id: Math.max(...items.map(item => item.id)) + 1,
    })

    this.setState({
      items: oneDayMore,
    })
  }

  deleteDay = (idDeletedDay, dayIndex) => {
    const {
      token,
      items,
    } = this.state
    const {
      idTripSelected,
      DELETE_ROW_PRICE,
      VALIDATE_STEP,
      LOADER,
    } = this.props
    VALIDATE_STEP('DaysList', false)
    const day = _.filter(items, o => o.id === idDeletedDay)[0]
    const idDay = day.id_title
    const idCircuit = day.id_circuit
    const deleteDays = _.filter(items, o => o.id !== idDeletedDay)
    this.setState({
      items: deleteDays,
      isValidated: false,
      isValidatedStruct: false,
    })
  }

  redirectManagement = (stateBack) => {
    // We push the global items to get it back
    const { history } = this.props
    const { items } = this.state
    const {
      page,
      state,
      isSelectionAction,
      nbMaxImg,
      currentImg,
      goBack,
    } = stateBack
    if (page === 'gallery') {
      history.push('gallery', {
        currentPage: 'daysList',
        isSelectionAction,
        nbMaxImg,
        currentImg,
        itemsGoBack: items,
        goBack,
        idDayLineModified: state.idDayLineModified,
      })
    } else {
      history.push(page, { state, items, goBack: 'daysList' })
    }
  }

  updateItems = (item) => {
    // replace item when update
    const { items } = this.state
    const indexItem = _.findIndex(items, o => o.id === item.id)
    const newItems = _.map(items, (o, index) => {
      if (index === indexItem) {
        return item
      }
      return o
    })
    this.setState({
      items: newItems,
      isValidated: false,
    })
    if (_.isEmpty(item.title)) {
      this.setState({
        isValidatedStruct: false,
      })
    }
  }

  // ******************************
  // Display components

  displayAuthorizationWarning = () => {
    const {
      tripDetails,
      userAccount,
    } = this.props
    const {
      userId,
    } = this.state
    return (
      <AllowedToChange
        userId={userId}
        profil={userAccount.profil}
        tripDetails={tripDetails}
      />
    )
  }

  // ********************************
  // Modal
  handleOpen = () => {
    this.setState({
      isModalOpen: true,
      isModalDisplayed: true,
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      isModalDisplayed: false,
    });
  };

  isModalOpenInChildren = (state) => {
    this.setState({ isModalDisplayed: state })
  }

  createNewVersion = () => {
    this.setState({
      isCreatedNewVersion: true,
    }, () => {
      // this.handleClose()
      this.validate()
    })
  }

  // **********************************************
  // if navigation by stepper
  saveData = (dest) => {
    const {
      history,
      userAccount,
      tripDetails,
      isNewTrip,
      isLoading,
    } = this.props
    const {
      userId,
      isValidated,
    } = this.state

    const isActionButtonDisabled = !isAllowed(userId, userAccount.profil, tripDetails) && !isNewTrip

    if (isLoading) return null
    if (isValidated || isActionButtonDisabled) {
      history.push(dest)
    } else {
      this.setState({
        navigateToDestination: dest,
      })
      this.checkRequiredInputs()
    }
    return true
  }

  checkRequiredInputs = (e, action) => {
    if (e) e.preventDefault()
    const {
      items,
      isValidated,
    } = this.state
    const {
      LOADER,
    } = this.props
    this.handleClose()
    const error = _.findIndex(items, o => !o.id_title || o.id_title === 1) >= 0
    if (error) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      LOADER(false)
      return null
    }
    this.setState({
      validateError: false,
      errorMsg: null,
    })
    if (!isValidated) {
      if (action === 'version') {
        this.createNewVersion()
      } else {
        this.validate()
      }
      // this.handleOpen()
    } else {
      this.validate()
    }
    return null
  }

  validate = () => {
    const {
      token,
      items,
      isCreatedNewVersion,
    } = this.state
    const {
      isNewTrip,
      tripDetails,
      tripContent,
      idTripSelected,
      PUT_TRIP_CONTENT,
      tripPrice,
      REORDER_DAYS_RANK,
      GET_TRIP_PRICE,
      LOADER,
      tripActivities,
    } = this.props
    LOADER(true)
    // scrollTo('main')

    const journee = []
    const hebergement = []
    const hebergement_opt = []
    const hebergement_opt1 = []
    const hebergement_opt2 = []
    const pension = []
    const type_chambre = []
    const nb_chambre = []
    const type_chambre_opt1 = []
    const nb_chambre_opt1 = []
    const type_chambre_opt2 = []
    const nb_chambre_opt2 = []
    const guide = []
    const chauffeur = []
    const dayContentPersonalized = []
    const itemsReduced = items.map(objet => ({
      id: objet.id,
    }));
    const tripActivitiesReduced = _.map(tripActivities, value => _.map(value, item => _.pick(item, ['day_index', 'id_act_cir'])));
    _.map(items, (o) => {
      journee.push(o.id_title)
      hebergement.push(!o.id_accomodation ? 1 : o.id_accomodation)
      hebergement_opt.push(`${o.id_accomodation_opt_1 || 1},${o.id_accomodation_opt_2 || 1}`)
      hebergement_opt1.push(!o.id_accomodation_opt_1 ? 1 : o.id_accomodation_opt_1)
      hebergement_opt2.push(!o.id_accomodation_opt_2 ? 1 : o.id_accomodation_opt_2)
      pension.push(!o.id_pension ? 1 : o.id_pension)
      type_chambre.push(_.isEmpty(o.id_room) ? 1 : o.id_room.join('-'))
      nb_chambre.push(_.isEmpty(o.nb_room) ? ['1'] : o.nb_room.join('-'))
      type_chambre_opt1.push(_.isEmpty(o.id_room_opt1) ? 1 : o.id_room_opt1.join('-'))
      nb_chambre_opt1.push(_.isEmpty(o.nb_room_opt1) ? ['1'] : o.nb_room_opt1.join('-'))
      type_chambre_opt2.push(_.isEmpty(o.id_room_opt2) ? 1 : o.id_room_opt2.join('-'))
      nb_chambre_opt2.push(_.isEmpty(o.nb_room_opt2) ? ['1'] : o.nb_room_opt2.join('-'))
      guide.push(o.guide || 0)
      chauffeur.push(o.driver || 0)

      if (o.personalized === '1' && o.active !== '1') {
        const dayContent = {
          id_journee: o.id_title ? o.id_title : o.id_title,
          nom_journee: o.title ? o.title : 'no title',
          nom_journee_court: o.shortTitle ? o.shortTitle : '',
          day_duration: o.day_duration ? o.day_duration : 1,
          day_activity: o.day_activity ? o.day_activity : '',
          id_start: o.id_start || null,
          id_end: o.id_end || null,
          transport: o.transport ? o.transport : '',
          distance: o.distance ? o.distance : '',
          categorie: '',
          image: o.image ? o.image : '',
        }
        dayContentPersonalized.push(dayContent)
      }
    })
    let newTripContent = {
      journee: journee.join(','),
      hebergement: hebergement.join(','),
      hebergement_opt: hebergement_opt.join(';'),
      hebergement_opt1: hebergement_opt1.join(','),
      hebergement_opt2: hebergement_opt2.join(','),
      pension: pension.join(','),
      type_chambre: type_chambre.join(','),
      nb_chambre: nb_chambre.join(','),
      type_chambre_opt1: type_chambre_opt1.join(','),
      nb_chambre_opt1: nb_chambre_opt1.join(','),
      type_chambre_opt2: type_chambre_opt2.join(','),
      nb_chambre_opt2: nb_chambre_opt2.join(','),
      guide: guide.join(','),
      chauffeur: chauffeur.join(','),
    }


    _.map(items, (o, index) => {
      const description = o.description.length > 0 ? o.description.toString().replace(/"/g, '\\"') : ''
      newTripContent = {
        ...newTripContent,
        [`desc_jour_${index + 1}`]: description,
      }
    })

    this.setState({
      isValidated: true,
      isValidatedStruct: true,
    })
    if (!_.isEmpty(newTripContent.journee)) {
      if (isNewTrip || tripContent[0].id_circuit === 1) {
        // it is new trip or there are no days yet (trip has been recorded without days in route)
        newTripContent.id_devis = idTripSelected
        PUT_TRIP_CONTENT(token, newTripContent, dayContentPersonalized, 'create')
      } else if (isCreatedNewVersion) {
        newTripContent.id_devis = idTripSelected
        newTripContent.id_demandeur = tripDetails[0].id_demandeur
        PUT_TRIP_CONTENT(token, newTripContent, dayContentPersonalized, 'version')
      } else if (!_.isEmpty(tripContent) && tripContent[0].id_circuit) {
        newTripContent.id_devis = idTripSelected
        newTripContent.id_circuit = tripContent[0].id_circuit
        PUT_TRIP_CONTENT(token, newTripContent, dayContentPersonalized, 'update')
      }
    } else {
      alert('something wrong, data route seems to be empty, cannot save data, please try again')
    }
    // update order daysrank
    if (idTripSelected && (!_.isEmpty(tripPrice) || !_.isEmpty(tripActivities))) {
      this.setState({
        isReordering: true,
      })
      setTimeout(() => {
        REORDER_DAYS_RANK(token, idTripSelected, itemsReduced, tripPrice, tripActivities)
      }, 500);
    }
    this.handleClose()
  }

  // Drag and drop builder function
  DNDContent(days, accomodation, accomodationOpt1, accomodationOpt2, pension, room, nbRoom, room_opt1, nbRoom_opt1, room_opt2, nbRoom_opt2) {
    const {
      locationList,
      tripContent,
    } = this.props
    const guide = tripContent ? _.split(tripContent[0].guide, ',') : []
    const chauffeur = tripContent ? _.split(tripContent[0].chauffeur, ',') : []

    const DNDDaysInfo = []
    _.map(days, (l, i) => {
      const id = i + 1
      const desc = `desc_jour_${id}`
      DNDDaysInfo.push({
        id,
        id_circuit: tripContent[0].id_circuit,
        id_journee: l.id_journee,
        start: !l.new && !_.isEmpty(locationList) && l.id_journee !== 1 ? (_.find(locationList, o => o.id_lieu === l.lieu_debut_journee)).lieu : '',
        id_start: !l.new && !_.isEmpty(locationList) && l.id_journee !== 1 ? (_.find(locationList, o => o.id_lieu === l.lieu_debut_journee)).id_lieu : '',
        title: !l.new ? l.nom_journee : '',
        shortTitle: !l.new && l.nom_journee_court ? l.nom_journee_court : '',
        id_title: !l.new ? l.id_journee : '',
        version: !l.new ? l.version : 1,
        description: !l.new && tripContent[0][desc] ? tripContent[0][desc] : '',
        transport: !l.new && l.transport ? l.transport : '',
        image: !l.new && l.image ? l.image : '',
        distance: !l.new && l.distance ? l.distance : '',
        active: !l.new && l.active ? l.active : '',
        day_duration: !l.new && l.day_duration ? l.day_duration : 1,
        day_activity: !l.new && l.day_activity ? l.day_activity : '',
        end: !l.new && !_.isEmpty(locationList) && l.id_journee !== 1 ? (_.find(locationList, o => o.id_lieu === l.lieu_fin_journee)).lieu : '',
        id_end: !l.new && !_.isEmpty(locationList) && l.id_journee !== 1 ? (_.find(locationList, o => o.id_lieu === l.lieu_fin_journee)).id_lieu : '',
        accomodation: !l.new && accomodation && accomodation[i] ? accomodation[i].nom_hebergement : '',
        id_accomodation: !l.new && accomodation && accomodation[i] ? accomodation[i].id_hebergement : '',
        pension: !l.new && pension && pension[i] ? pension[i].pension : '',
        id_pension: !l.new && pension && pension[i] ? pension[i].id_pension : '',
        room: !l.new && room && room[i] ? room[i].map(r => r.type_chambre) : [],
        id_room: !l.new && room && room[i] ? room[i].map(r => r.id_heb_ch) : [],
        nb_room: !l.new && !_.isEmpty(nbRoom) && nbRoom[i] !== 'null' ? nbRoom[i].split('-') : [],
        room_opt1: !l.new && room_opt1 && room_opt1[i] ? room_opt1[i].map(r => r.type_chambre) : [],
        id_room_opt1: !l.new && room_opt1 && room_opt1[i] ? room_opt1[i].map(r => r.id_heb_ch) : [],
        nb_room_opt1: !l.new && !room_opt1.warning && !_.isEmpty(nbRoom_opt1) && nbRoom_opt1[i] && nbRoom_opt1[i] !== 'null' ? nbRoom_opt1[i].split('-') : [],
        room_opt2: !l.new && room_opt2 && room_opt2[i] ? room_opt2[i].map(r => r.type_chambre) : [],
        id_room_opt2: !l.new && room_opt2 && room_opt2[i] ? room_opt2[i].map(r => r.id_heb_ch) : [],
        nb_room_opt2: !l.new && !room_opt2.warning && !_.isEmpty(nbRoom_opt2) && nbRoom_opt2[i] && nbRoom_opt2[i] !== 'null' ? nbRoom_opt2[i].split('-') : [],
        guide: !l.new && tripContent ? guide[i] : '0',
        driver: !l.new && tripContent ? chauffeur[i] : '0',
        id_accomodation_opt_1: !l.new && accomodationOpt1 && accomodationOpt1[i] ? accomodationOpt1[i].id_hebergement : '',
        accomodation_opt_1: !l.new && accomodationOpt1 && accomodationOpt1[i] ? accomodationOpt1[i].nom_hebergement : '',
        id_accomodation_opt_2: !l.new && accomodationOpt2 && accomodationOpt2[i] ? accomodationOpt2[i].id_hebergement : '',
        accomodation_opt_2: !l.new && accomodationOpt2 && accomodationOpt2[i] ? accomodationOpt2[i].nom_hebergement : '',
        personalized: !l.new && l.active === '1' ? '0' : '1',
      })
    })
    return DNDDaysInfo
  }

  render() {
    const {
      userId,
      items,
      validateError,
      errorMsg,
      validMsg,
      isValidated,
      isValidatedStruct,
      isModalOpen,
      isModalDisplayed,
      isRedirected,
    } = this.state
    const {
      isLoading,
      tripDetails,
      userAccount,
      isNewTrip,
    } = this.props
    const classContainer = isLoading ? 'container2' : 'container'
    const isActionButtonDisabled = !_.isEmpty(tripDetails) && !_.isEmpty(userAccount) ? !isAllowed(userId, userAccount.profil, tripDetails) : true
    return (
      <div id="main" className={classContainer}>
        <Template>
          <div className="requestContainer">
            <Stepper saveData={dest => this.saveData(dest)} />
            <div className="dividerStepper" />
            {isLoading ? <Loader /> : <div className="loader-null" />}
            <div className="daysList">
              <h2>DAYS LIST</h2>
              {!isNewTrip && this.displayAuthorizationWarning()}
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {!isLoading
                ? (
                  <div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {/* {(provided, snapshot) => ( */}
                        {provided => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {items.map((item, index) => (
                              <Draggable key={`item_${item.id}`} draggableId={item.id.toString(10)} index={index}>
                                {/* {(provided, snapshot) => ( */}
                                {providedDrag => (
                                  <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    {...providedDrag.dragHandleProps}
                                  >
                                    <DayLine
                                      key={item.id}
                                      item={item}
                                      index={index}
                                      validateError={validateError}
                                      isValidated={isValidatedStruct}
                                      updateItems={newItem => this.updateItems(newItem)}
                                      redirectManagement={stateBack => this.redirectManagement(stateBack)}
                                      deleteDay={idDeletedDay => this.deleteDay(idDeletedDay, index)}
                                      redirectFlag={redirect => this.setState({ isRedirected: redirect })}
                                      isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                                      isActionButtonDisabled={isActionButtonDisabled && !isNewTrip}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                ) : ''}
              <div className="addDayBtn">
                <Fab
                  aria-label="Add"
                  size="large"
                  disabled={(!_.isEmpty(items) && !items[0].title) || isActionButtonDisabled || isLoading}
                  onClick={this.addDayLine}
                >
                  <AddIcon />
                </Fab>

              </div>
            </div>
            <CssGrid
              container
              spacing={2}
              className="actionContainer"
            >
              <CssGrid item md={4} xs={12}>
                <ActionButton
                  label={isValidated ? 'Validate' : 'Update'}
                  onClick={e => this.checkRequiredInputs(e, 'update')}
                  disabled={isActionButtonDisabled || isLoading}
                />
              </CssGrid>
            </CssGrid>
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.createNewVersion}
            onResponseYes={this.validate}
            type="version"
            title="do you want to updade the current version or create a new one?"
          />
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated && !isModalDisplayed && !isRedirected}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  putTripContent: state.api.putTripContent,
  isTripDetailsStepValidated: state.api.isTripDetailsStepValidated,
  idTripSelected: state.api.idTripSelected,
  isNewTrip: state.api.isNewTrip,
  isDetailModified: state.api.isDetailModified,
  tripDetails: state.api.tripDetails,
  tripContent: state.api.tripContent,
  tripAllDays: state.api.tripAllDays,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  tripPension: state.api.tripPension,
  tripRoom: state.api.tripRoom,
  tripRoomOpt1: state.api.tripRoomOpt1,
  tripRoomOpt2: state.api.tripRoomOpt2,
  tripPrice: state.billingReducer.tripPrice,
  reorderStatus: state.billingReducer.reorderStatus,
  daysList: state.api.daysList,
  putDayContent: state.api.putDayContent,
  accomodationList: state.api.accomodationList,
  locationList: state.api.locationList,
  deleteRowPrice: state.billingReducer.deleteRowPrice,
  userAccount: state.userAccountReducer.userAccount,
  tripActivities: state.billingReducer.tripActivities,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  ACTIVE_STEP: activeStep => dispatch({
    type: 'ACTIVE_STEP',
    activeStep,
  }),
  VALIDATE_STEP: (page, value) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
  }),
  PUT_DAY_CONTENT: (token, dayContent, action) => dispatch({
    type: 'PUT_DAY_CONTENT',
    token,
    dayContent,
    action,
  }),
  ID_TRIP_SELECT: (idTripSelected, isNewTrip, isDetailModified) => dispatch({
    type: 'ID_TRIP_SELECT',
    idTripSelected,
    isNewTrip,
    isDetailModified,
  }),
  REORDER_DAYS_RANK: (token, idDevis, days, tripPrice, tripActivities) => dispatch({
    type: 'REORDER_DAYS_RANK',
    token,
    idDevis,
    days,
    tripPrice,
    tripActivities,
  }),
  GET_LIST_CIRCUIT: (token, dateTampon, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    filterAdvisor,
  }),
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  GET_TRIP_ALLDAYS: (tripDaysList, idCircuit) => dispatch({
    type: 'GET_TRIP_ALLDAYS',
    tripDaysList,
    idCircuit,
  }),
  GET_TRIP_ALLDAYS_ACCOMODATION: (tripDaysList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ALLDAYS_ACCOMODATION',
    tripDaysList,
    tripAccoList,
  }),
  GET_TRIP_ACCOMODATION: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT1: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT1',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT2: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT2',
    tripAccomodationList,
  }),
  GET_TRIP_PENSION: tripPensionList => dispatch({
    type: 'GET_TRIP_PENSION',
    tripPensionList,
  }),
  RESET_DAY_CONTENT: () => dispatch({
    type: 'RESET_DAY_CONTENT',
  }),
  GET_TRIP_ROOM: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT1: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT1',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT2: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT2',
    tripRoomList,
    tripAccoList,
  }),
  PUT_TRIP_CONTENT: (token, newTripContent, dayContentPersonalized, action) => dispatch({
    type: 'PUT_TRIP_CONTENT',
    token,
    newTripContent,
    dayContentPersonalized,
    action,
  }),
  RESET_TRIP_CONTENT: () => dispatch({
    type: 'RESET_TRIP_CONTENT',
  }),
  ROW_TRIP_SELECT: allRows => dispatch({
    type: 'ROW_TRIP_SELECT',
    allRows,
  }),
  GET_TRIP_DEVIS_FINAL: (token, idDevis) => dispatch({
    type: 'GET_TRIP_DEVIS_FINAL',
    token,
    idDevis,
  }),
  DELETE_ROW_PRICE: (token, idDevis, idDay, idCircuit, dayIndex) => dispatch({
    type: 'DELETE_ROW_PRICE',
    token,
    idDevis,
    idDay,
    idCircuit,
    dayIndex,
  }),
  RESET_PUT_TRIP_PRICE: () => dispatch({
    type: 'RESET_PUT_TRIP_PRICE',
  }),
  RESET_REORDER_STATUS: () => dispatch({
    type: 'RESET_REORDER_STATUS',
  }),
  GET_TRIP_PRICE: (token, idDevis) => dispatch({
    type: 'GET_TRIP_PRICE',
    token,
    idDevis,
  }),
  GET_TRIP_ACTIVITIES: (token, idCircuit) => dispatch({
    type: 'GET_TRIP_ACTIVITIES',
    token,
    idCircuit,
  }),
  PUT_TRIP_ACCOMODATION_ACTIVITY: (token, tripAllDaysAccomodations, tripActivities, idTripSelected, idAcco) => dispatch({
    type: 'PUT_TRIP_ACCOMODATION_ACTIVITY',
    token,
    tripAllDaysAccomodations,
    tripActivities,
    idTripSelected,
    idAcco,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DaysList))
