import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import MenuItem from '@material-ui/core/MenuItem';
import { CssGrid } from '../../configCSS'
import {
  Template,
  Stepper,
  ActionButton,
  Input,
  ModalComponent,
  Loader,
  Uploader,
  TextEditor,
  AllowedToChange,
} from '../../components'
import {
  DisplayLocalisationMap,
  SelectRequestor,
} from './component'
import isAllowed from '../../components/utils/profilAuthorization'
import stage from '../../config'
import scrollTo from '../../components/utils/scrollTo/scrollTo'

const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage


class TripDetails extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    LOADER: PropTypes.func.isRequired,
    GET_LIST_CIRCUIT: PropTypes.func.isRequired,
    GET_TRIP_DETAILS: PropTypes.func.isRequired,
    PUT_TRIP_DETAILS: PropTypes.func.isRequired,
    GET_TRIP_BILLING: PropTypes.func.isRequired,
    RESET_TRIP_DETAILS: PropTypes.func.isRequired,
    GET_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    GET_TRIP_CONTENT: PropTypes.func.isRequired,
    ID_TRIP_SELECT: PropTypes.func.isRequired,
    tripDetails: PropTypes.array,
    putTripDetails: PropTypes.object,
    idTripSelected: PropTypes.number,
    isNewTrip: PropTypes.bool,
    isDuplicatedTrip: PropTypes.bool,
    isTripStepValidated: PropTypes.bool.isRequired,
    statusList: PropTypes.array,
    ACTIVE_STEP: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    GET_REQUEST: PropTypes.func.isRequired,
    PUT_REQUEST: PropTypes.func.isRequired,
    RESET_REQUEST: PropTypes.func.isRequired,
    GET_CUSTOMER_LIST: PropTypes.func.isRequired,
    userAccount: PropTypes.object,
    informationsContent: PropTypes.object,
    collaborators: PropTypes.array,
    locationList: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    customerList: PropTypes.array,
    putRequest: PropTypes.object,
  }

  static defaultProps = {
    isLoading: true,
    userAccount: {},
    tripDetails: [],
    locationList: [],
    tripAllDaysAccomodations: [],
    putTripDetails: {},
    idTripSelected: 0,
    isNewTrip: false,
    isDuplicatedTrip: false,
    statusList: [],
    informationsContent: {},
    collaborators: [],
    customerList: [],
    putRequest: {},
  }

  constructor(props) {
    super(props);
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.chooseAction = this.chooseAction.bind(this)
    this.checkRequiredInputs = this.checkRequiredInputs.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleLocalisationChange = this.handleLocalisationChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.createNewVersion = this.createNewVersion.bind(this)
    this.updateInformations = this.updateInformations.bind(this)
    this.goToTravelerList = this.goToTravelerList.bind(this)
    this.saveData = this.saveData.bind(this)
    this.displayTripPart = this.displayTripPart.bind(this)
    this.displayCustomerPart = this.displayCustomerPart.bind(this)
    this.displayOptionPart = this.displayOptionPart.bind(this)
    this.displayInformationPart = this.displayInformationPart.bind(this)
    this.displayCommentPart = this.displayCommentPart.bind(this)
    this.displayAuthorizationWarning = this.displayAuthorizationWarning.bind(this)
    this.createOperatorRequest = this.createOperatorRequest.bind(this)
    this.updateRequestor = this.updateRequestor.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      tripDetails: [
        {
          id_circuit: '',
          id_devis: null,
          nom_devis: '',
          nom_programme: '',
          version: 1,
          destination: '',
          desc_voyage: '',
          centre_interet: '',
          nom_responsable_groupe: '',
          prenom_responsable_groupe: '',
          e_mail: '',
          nb_senior: '',
          nb_adulte: '',
          nb_enfant: '',
          nb_bebe: '',
          nombre_chambre: '',
          prix_comprend: '',
          prix_comprend_pas: '',
          langage: 'en',
          code_devis: '',
          status: 'in progress',
          statusHasChanged: false,
          statusIsClosed: false,
          categorie: '',
          date_creation: '',
          date_modif: '',
          date_depart: '',
          image: '',
          cover_image: '',
          map_localisation: '',
          id_conseiller: parseInt(localStorage.getItem('userId'), 10),
        },
      ],
      action: '',
      newImageArray_image: [],
      newURLArray_image: [],
      newImageArray_cover_image: [],
      newURLArray_cover_image: [],
      detailsHasChanged: false,
      customerHasChanged: false,
      tripNameHasChanged: false,
      modalTitle: '',
      modalType: '',
      isModalOpen: false,
      isModalOpen2: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      navigateToDestination: 'daysList',
      isRedirected: false,
      isValidateAction: false,
      isFetchingData: false,
      newStatus: '',
      isInitEditor: true,
      isOperatorAssigned: false,
      isRequestorFieldDisabled: false,
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      userAccount,
      LOADER,
      GET_TRIP_DETAILS,
      GET_USER_ACCOUNT,
      ACTIVE_STEP,
      idTripSelected,
      isTripStepValidated,
    } = this.props
    const { token } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // come back from 'Gallery'
    if (history.location.state) {
      const {
        state,
        currentImg,
        pictureSelected,
      } = history.location.state
      this.setState({
        tripDetails: [{
          ...state.item,
          [state.informationImage]: pictureSelected ? pictureSelected.join() : currentImg.join(),
        }],
        detailsHasChanged: !!pictureSelected && pictureSelected !== currentImg,
      })
    } else if (idTripSelected) {
      // get details journey if a trip has been selected
      this.setState({
        isFetchingData: true,
      })

      GET_TRIP_DETAILS(token, idTripSelected)
      // Active Loader
      LOADER(true)
    }
    // check userAccount
    if (_.isEmpty(userAccount)) {
      GET_USER_ACCOUNT(userId)
    }
    // define active step for Stepper
    ACTIVE_STEP('tripDetails')
    // redirect if preview step is not active
    if (!isTripStepValidated) history.push('trip')
  }

  componentWillReceiveProps(nextProps) {
    const {
      token,
      userId,
      tripDetails: tripDetailsOld,
      isRedirected,
      isValidateAction,
      newStatus,
      isFetchingData,
      isOperatorAssigned,
      action,
    } = this.state
    const {
      tripDetails,
      putTripDetails,
      idTripSelected,
      isNewTrip,
      history,
      putRequest,
      LOADER,
      GET_LIST_CIRCUIT,
      GET_TRIP_DETAILS,
      GET_TRIP_BILLING,
      GET_TRIP_DEVIS_FINAL,
      RESET_TRIP_DETAILS,
      GET_TRIP_CONTENT,
      VALIDATE_STEP,
      ID_TRIP_SELECT,
      ROW_TRIP_SELECT,
      RESET_REQUEST,
      GET_REQUEST,
      GET_CUSTOMER_LIST,
    } = nextProps
    // check if token is always valid
    if (!_.isEmpty(tripDetails) && tripDetails[0].error) {
      localStorage.setItem('isLogged', false)
      alert(tripDetails[0].error)
      history.push('/')
    }
    // **********************************
    // After updating customer validate details change
    if (putTripDetails && putTripDetails.success && putTripDetails.idRequestor) {
      RESET_TRIP_DETAILS()
      GET_CUSTOMER_LIST(userId)
      this.setState({
        customerHasChanged: false,
        tripDetails: [{
          ...putTripDetails.tripDetails,
          id_demandeur: putTripDetails.idRequestor,
        }],
      }, () => setTimeout(() => {
        this.chooseAction(action)
      }, 300))
    }
    // Validate recording and go to next step
    if (putTripDetails && putTripDetails.success && putTripDetails.id) {
      // const { newTrip } = putTripDetails || false
      const { isNewId } = putTripDetails || false
      this.setState({
        tripNameHasChanged: false,
        validMsg: 'Changes were successfully recorded !',
        // isFetchingData: true,
        detailsHasChanged: false,
      })
      GET_TRIP_DETAILS(token, putTripDetails.id)
      GET_TRIP_BILLING(token, putTripDetails.id)
      RESET_TRIP_DETAILS()
      // reset tripDevisFinall
      ID_TRIP_SELECT(putTripDetails.id, isNewTrip, true)
      // Validate this step
      VALIDATE_STEP('TripDetails', true)
      // create a request if Operator is assigned
      if (isOperatorAssigned) {
        this.createOperatorRequest()
      }
      // get content informations about this trip
      if (!isNewTrip && tripDetails[0].id_circuit > 1) {
        GET_TRIP_CONTENT(tripDetails[0].id_circuit)
        GET_TRIP_DEVIS_FINAL(token, tripDetails[0].id_devis)
      }
      if (!isNewTrip && tripDetails && tripDetails[0].id_circuit === null) {
        ID_TRIP_SELECT(idTripSelected, true, true)
      }
      // reset selected row in trip list page
      if (isNewId) {
        ROW_TRIP_SELECT([])
      }
      LOADER(false)
      GET_LIST_CIRCUIT(token, '2020-01', userId)
      this.resetValidMsg()
    }
    // update trip list
    if (!_.isEqual(tripDetails, tripDetailsOld) && isFetchingData && idTripSelected && !isRedirected && !isValidateAction && !newStatus) {
      this.setState({
        tripDetails,
        isFetchingData: false,
        isRequestorFieldDisabled: !!tripDetails[0].id_demandeur,
      })
      LOADER(false)
    }
    if (!_.isEmpty(history.location.state) && history.location.state.pictureSelected) {
      this.setState({
        detailsHasChanged: true,
      })
    }
    // Put request result
    if (putRequest && putRequest.success) {
      RESET_REQUEST()
      GET_REQUEST(token)
    }
  }

  componentWillUnmount() {
    const { ACTIVE_STEP } = this.props
    ACTIVE_STEP('')
  }

  resetValidMsg = () => {
    const { navigateToDestination } = this.state
    const { history } = this.props
    setTimeout(() => {
      this.setState({
        validMsg: null,
      }, () => {
        // go to next destination
        if (navigateToDestination) history.push(navigateToDestination)
      })
    }, 2000)
  }

  // ********************************************************
  // create operator request
  createOperatorRequest = () => {
    const {
      userId,
      token,
      tripDetails,
    } = this.state
    const {
      customerList,
      PUT_REQUEST,
    } = this.props
    const {
      nom_devis,
      id_demandeur,
      id_operator,
      destination,
      date_depart,
      comment,
    } = tripDetails[0]
    const demandeur = customerList.filter(c => c.id_demandeur === id_demandeur)[0]
    const request = {
      firstname: demandeur ? demandeur.prenom_responsable_groupe : '',
      lastname: demandeur ? demandeur.nom_responsable_groupe : '',
      email: demandeur ? demandeur.e_mail : '',
      trip_name: nom_devis,
      country: destination || '',
      date_departure: date_depart || null,
      details: comment || '',
      id_conseiller: userId,
      id_conseiller_dest: id_operator,
      status: 'pending operator',
    }
    PUT_REQUEST(token, request)
  }

  // *************************************************************
  // inputs change
  handleEditorChange = field => (content) => {
    const {
      tripDetails,
      isInitEditor,
    } = this.state
    this.setState({
      tripDetails: [{
        ...tripDetails[0],
        [field]: content,
      }],
      detailsHasChanged: !isInitEditor,
      isInitEditor: false,
    });
  }

  handleLocalisationChange = field => (content) => {
    const {
      tripDetails,
    } = this.state
    const {
      tripAllDaysAccomodations,
    } = this.props
    if (!tripDetails[0].id_devis) return null
    const tabLocalisationFromRoute = !_.isEmpty(tripAllDaysAccomodations) ? tripAllDaysAccomodations.map(item => (item.start_location)) : []
    if (field === 'reset') {
      this.setState({
        tripDetails: [{
          ...tripDetails[0],
          map_localisation: _.join(tabLocalisationFromRoute, ','),
        }],
        detailsHasChanged: true,
      });
      if (_.isEmpty(tripDetails[0].destination)) {
        this.setState({
          tripDetails: [{
            ...tripDetails[0],
            destination: _.join(tabLocalisationFromRoute, ', '),
          }],
          detailsHasChanged: true,
        });
      }
    } else if (typeof content === 'object') {
      this.setState({
        tripDetails: [{
          ...tripDetails[0],
          [field]: _.join(content, ','),
        }],
        detailsHasChanged: true,
      });
    }
    return true
  }

 handleChange = field => (event) => {
   const { tripDetails } = this.state
   const { ROW_TRIP_SELECT } = this.props
   const { value } = event.target
   this.setState({
     tripDetails: [{
       ...tripDetails[0],
       [field]: value,
       statusHasChanged: field === 'status' ? true : tripDetails[0].statusHasChanged,
       statusIsClosed: ['completed', 'canceled', 'no answer'].indexOf(value) > -1,
     }],
     detailsHasChanged: true,
   });
   // check what fields change

   // trip status will be "confirmed", ask to fill traveler list
   if (field === 'status' && value === 'confirmed') {
     this.setState({
       newStatus: 'confirmed',
     })
     this.handleOpen('travelerList')
   }
   // requestor change and it's not null
   if ((field === 'nom_responsable_groupe' || field === 'prenom_responsable_groupe' || field === 'e_mail')) {
     this.setState({
       customerHasChanged: true,
     })
   }
   // trip title change
   if (field === 'nom_devis' && event.target.value !== '') {
     this.setState({
       tripNameHasChanged: true,
     })
     ROW_TRIP_SELECT([])
   }
   if (field === 'id_operator') {
     this.setState({
       isOperatorAssigned: !!value,
     })
   }
 }

 handleDefaultChange = field => () => {
   const { tripDetails } = this.state
   const { informationsContent, userAccount } = this.props
   let source = ''
   let fieldtoUpdate = ''
   switch (field) {
     case 'comment':
       source = `${userAccount.language}_remarque`
       fieldtoUpdate = 'comment'
       break;
     case 'prix_comprend':
       source = `${userAccount.language}_information_3_description`
       fieldtoUpdate = 'prix_comprend'
       break;
     case 'prix_comprend_pas':
       source = `${userAccount.language}_information_4_description`
       fieldtoUpdate = 'prix_comprend_pas'
       break;
     default:
       source = ''
   }
   this.setState({
     tripDetails: [{
       ...tripDetails[0],
       [fieldtoUpdate]: informationsContent[source],
     }],
     detailsHasChanged: true,
   });
 }


  updateRequestor = (requestor) => {
    const { tripDetails } = this.state
    const {
      id_demandeur,
      nom_responsable_groupe,
      prenom_responsable_groupe,
      e_mail,
      telephone,
      society,
      isRequestorFieldDisabled,
    } = requestor
    this.setState({
      tripDetails: [{
        ...tripDetails[0],
        id_demandeur,
        nom_responsable_groupe,
        prenom_responsable_groupe,
        e_mail,
        telephone,
        society,
      }],
      detailsHasChanged: true,
      // customerHasChanged: true,
      isRequestorFieldDisabled: isRequestorFieldDisabled || false,
    })
  }

  // ***************************************
  // display component
  displayTripPart = () => {
    const {
      tripDetails,
      validateError,
    } = this.state
    const { informationsContent } = this.props
    const { theme_devis } = informationsContent
    return (
      <div className="elevationBox">
        <h3 className="titleForm">TRIP</h3>
        <CssGrid item xs={12}>
          <Input
            id="programName"
            label="Program Name"
            value={!_.isEmpty(tripDetails) && tripDetails[0].nom_devis ? tripDetails[0].nom_devis : ''}
            onChange={this.handleChange('nom_devis')}
            margin="normal"
            variant="outlined"
            required
            error={validateError}
          />
        </CssGrid>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={10}>
            <Input
              id="programTitle"
              label="Program Title"
              value={!_.isEmpty(tripDetails) && tripDetails[0].nom_programme ? tripDetails[0].nom_programme : ''}
              onChange={this.handleChange('nom_programme')}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item xs={2}>
            <Input
              id="version"
              label="Version"
              value={!_.isEmpty(tripDetails) && tripDetails[0].version ? tripDetails[0].version : 1}
              onChange={this.handleChange('version')}
              margin="normal"
              variant="outlined"
              type="number"
            />
          </CssGrid>
        </CssGrid>
        {informationsContent.theme_devis === 'Horizon' && (
          <CssGrid item xs={12}>
            <Input
              id="destination"
              label="Destination"
              select
              value={!_.isEmpty(tripDetails) && tripDetails[0].destination ? tripDetails[0].destination : ''}
              onChange={this.handleChange('destination')}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value="VIETNAM">VIETNAM</MenuItem>
              <MenuItem value="VIETNAM, CAMBODGE">VIETNAM, CAMBODGE</MenuItem>
              <MenuItem value="VIETNAL, LAOS, CAMBODGE">VIETNAL, LAOS, CAMBODGE</MenuItem>
              <MenuItem value="CAMBODGE">CAMBODGE</MenuItem>
              <MenuItem value="LAOS, CAMBODGE">LAOS, CAMBODGE</MenuItem>
              <MenuItem value="LAOS">LAOS</MenuItem>
              <MenuItem value="">none</MenuItem>
            </Input>
          </CssGrid>
        )
        }
        <CssGrid item xs={12}>
          <div className="editorStyle">
            <p className="editorTitle background-details">Description</p>
            <TextEditor
              object={tripDetails[0]}
              value={tripDetails[0].desc_voyage}
              handleEditorChange={this.handleEditorChange('desc_voyage')}
            />
          </div>
        </CssGrid>
        {(informationsContent.theme_devis === 'parfum' || informationsContent.theme_devis === 'parfum2') && (
        <CssGrid item xs={12}>
          <div className="editorStyle">
            <Input
              id="video"
              label="video link"
              value={!_.isEmpty(tripDetails) && tripDetails[0].centre_interet ? tripDetails[0].centre_interet : ''}
   // value={!_.isEmpty(tripDetails) && tripDetails[0].code_devis ? tripDetails[0].code_devis : `D-${companyContent.company.slice(0, 3).toUpperCase()}-${moment().format('YYYY-MM-DD')}`}
              onChange={this.handleChange('centre_interet')}
              margin="normal"
              variant="outlined"
              width="100%"
            />
          </div>
        </CssGrid>
        )}
        {
          ['prenium', 'duyen', 'duyen-template', 'parfum', 'parfum2'].includes(theme_devis)
            ? null
            : (
              <CssGrid item xs={12}>
                <div className="editorStyle">
                  <p className="editorTitle background-details">Center Of Interest</p>
                  <TextEditor
                    id="centerOfInterest"
                    label="Center Of Interest"
                    object={tripDetails[0]}
                    value={!_.isEmpty(tripDetails) && tripDetails[0].centre_interet ? tripDetails[0].centre_interet : ''}
                    handleEditorChange={this.handleEditorChange('centre_interet')}
                  />
                </div>
              </CssGrid>
            )
        }
      </div>
    )
  }

  displayCustomerPart = () => {
    const {
      tripDetails,
      isRequestorFieldDisabled,
    } = this.state
    const {
      informationsContent,
      customerList,
    } = this.props

    const { theme_devis } = informationsContent

    return (
      <div className="elevationBox">
        <h3 className="titleForm">CUSTOMER</h3>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={6}>
            {tripDetails && (
              <SelectRequestor
                requestorList={customerList}
                requestorId={tripDetails[0].id_demandeur}
                updateRequestor={requestor => this.updateRequestor(requestor)}
              />
            )}
          </CssGrid>
          <CssGrid item xs={6}>
            <Input
              id="society"
              label="Society"
              value={!_.isEmpty(tripDetails) && tripDetails[0].society ? tripDetails[0].society : ''}
              onChange={this.handleChange('society')}
              margin="normal"
              variant="outlined"
              disabled={isRequestorFieldDisabled}
            />
          </CssGrid>
        </CssGrid>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={6}>
            <Input
              id="lastname"
              label="Lastname"
              value={!_.isEmpty(tripDetails) && tripDetails[0].nom_responsable_groupe ? tripDetails[0].nom_responsable_groupe : ''}
              onChange={this.handleChange('nom_responsable_groupe')}
              disabled={isRequestorFieldDisabled}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item xs={6}>
            <Input
              id="firstname"
              label="Firstname"
              value={!_.isEmpty(tripDetails) && tripDetails[0].prenom_responsable_groupe ? tripDetails[0].prenom_responsable_groupe : ''}
              onChange={this.handleChange('prenom_responsable_groupe')}
              disabled={isRequestorFieldDisabled}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
        </CssGrid>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={6}>
            <Input
              id="mail"
              label="Mail"
              value={!_.isEmpty(tripDetails) && tripDetails[0].e_mail ? tripDetails[0].e_mail : ''}
              onChange={this.handleChange('e_mail')}
              disabled={isRequestorFieldDisabled}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item xs={6}>
            <Input
              id="phone"
              label="Phone"
              value={!_.isEmpty(tripDetails) && tripDetails[0].telephone ? tripDetails[0].telephone : ''}
              onChange={this.handleChange('telephone')}
              disabled={isRequestorFieldDisabled}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
        </CssGrid>
        <h4 className="titleForm">Number of travelers</h4>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={3}>
            <Input
              id="Senior"
              label="Adult A number"
              type="number"
              value={!_.isEmpty(tripDetails) && tripDetails[0].nb_senior ? tripDetails[0].nb_senior : ''}
              onChange={this.handleChange('nb_senior')}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item xs={3}>
            <Input
              id="adult"
              label="Adult B number"
              type="number"
              value={!_.isEmpty(tripDetails) && tripDetails[0].nb_adulte ? tripDetails[0].nb_adulte : ''}
              onChange={this.handleChange('nb_adulte')}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item xs={3}>
            <Input
              id="children"
              label="Children number"
              type="number"
              value={!_.isEmpty(tripDetails) && tripDetails[0].nb_enfant ? tripDetails[0].nb_enfant : ''}
              onChange={this.handleChange('nb_enfant')}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item xs={3}>
            <Input
              id="baby"
              label="Baby number"
              type="number"
              value={!_.isEmpty(tripDetails) && tripDetails[0].nb_bebe ? tripDetails[0].nb_bebe : ''}
              onChange={this.handleChange('nb_bebe')}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          {/* <CssGrid item xs={6}>
            <Input
              id="rooms"
              label="Rooms number"
              value={!_.isEmpty(tripDetails) && tripDetails[0].nombre_chambre ? tripDetails[0].nombre_chambre : ''}
              onChange={this.handleChange('nombre_chambre')}
              margin="normal"
              variant="outlined"
            />
          </CssGrid> */}
        </CssGrid>
        {
          (theme_devis === 'parfum' || theme_devis === 'parfum2')
            && (
              <CssGrid
                container
                spacing={2}
              >
                <CssGrid item xs={6}>
                  <h4 className="titleForm">Accomodation type</h4>
                  <Input
                    id="code"
                    label="accomodation Type"
                    value={!_.isEmpty(tripDetails) && tripDetails[0].destination ? tripDetails[0].destination : ''}
                    onChange={this.handleChange('destination')}
                    margin="normal"
                    variant="outlined"
                    width="100%"
                  />
                </CssGrid>
              </CssGrid>
            )

        }
      </div>
    )
  }

  displayOptionPart = () => {
    const {
      tripDetails,
    } = this.state
    const {
      informationsContent,
    } = this.props

    const { theme_devis } = informationsContent
    return (
      <div className="elevationBox">
        <h3 className="titleForm">OPTIONS</h3>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={6}>
            <TextEditor
              id="Include"
              label="include"
              object={tripDetails[0]}
              value={!_.isEmpty(tripDetails) && tripDetails[0].prix_comprend}
              handleEditorChange={this.handleEditorChange('prix_comprend')}
            />
            {theme_devis !== 'Horizon' && (
            <div className="buttons-collection">
              <button
                type="submit"
                className="select-localisation-button"
                onClick={this.handleDefaultChange('prix_comprend')}
              >
                load default price included
              </button>
            </div>
            )}
          </CssGrid>
          <CssGrid item xs={6}>
            <TextEditor
              id="notInclude"
              label="Not include"
              object={tripDetails[0]}
              value={!_.isEmpty(tripDetails) && tripDetails[0].prix_comprend_pas}
              handleEditorChange={this.handleEditorChange('prix_comprend_pas')}
            />
            {theme_devis !== 'Horizon' && (
            <div className="buttons-collection">
              <button
                type="submit"
                className="select-localisation-button"
                onClick={this.handleDefaultChange('prix_comprend_pas')}
              >
                load default price not included
              </button>
            </div>
            )}
          </CssGrid>
        </CssGrid>
        {
          ['prenium'].includes(theme_devis)
            ? (
              <CssGrid item xs={12}>
                <div className="editorStyle">
                  <p className="editorTitle background-details">Price of visits</p>
                  <TextEditor
                    object={tripDetails[0]}
                    value={tripDetails[0].prix_visites}
                    handleEditorChange={this.handleEditorChange('prix_visites')}
                  />
                </div>
              </CssGrid>
            )
            : null
        }
      </div>
    )
  }

  displayInformationPart = () => {
    const {
      userId,
      tripDetails,
    } = this.state
    const {
      statusList,
      userAccount,
      informationsContent,
      collaborators,
      locationList,
    } = this.props
    const { theme_devis } = informationsContent
    return (
      <div className="elevationBox">
        <h3 className="titleForm">INFORMATIONS</h3>
        <CssGrid item xs={12}>
          <Input
            id="langage"
            label="Langage"
            value={userAccount.language}
            margin="normal"
            variant="outlined"
            width="100%"
            disabled
          />
        </CssGrid>
        {
          theme_devis === 'prenium'
            ? null
            : (
              <CssGrid item xs={12}>
                <Input
                  id="code"
                  label="Code"
                  value={!_.isEmpty(tripDetails) && tripDetails[0].code_devis ? tripDetails[0].code_devis : ''}
   // value={!_.isEmpty(tripDetails) && tripDetails[0].code_devis ? tripDetails[0].code_devis : `D-${companyContent.company.slice(0, 3).toUpperCase()}-${moment().format('YYYY-MM-DD')}`}
                  onChange={this.handleChange('code_devis')}
                  margin="normal"
                  variant="outlined"
                  width="100%"
                />
              </CssGrid>
            )
        }
        <CssGrid item xs={12}>
          <Input
            id="status"
            label="Status"
            select
            value={!_.isEmpty(tripDetails) && tripDetails[0].status ? tripDetails[0].status : 'template'}
            onChange={this.handleChange('status')}
            margin="normal"
            variant="outlined"
            width="100%"
          >
            {_.map(statusList, (l, k) => <MenuItem key={k} value={l.status} disabled={l.status === 'template' && userAccount.profil > 2}>{l.status}</MenuItem>)}
          </Input>
        </CssGrid>
        <CssGrid item xs={12}>
          <Input
            id="category"
            label="Category"
            value={!_.isEmpty(tripDetails) && tripDetails[0].categorie ? tripDetails[0].categorie : ''}
            onChange={this.handleChange('categorie')}
            margin="normal"
            variant="outlined"
            width="100%"
          />
        </CssGrid>
        <CssGrid item xs={12}>
          <Input
            id="date_depart"
            label="Date of departure"
            type="date"
            value={!_.isEmpty(tripDetails) && tripDetails[0].date_depart ? tripDetails[0].date_depart : ''}
            onChange={this.handleChange('date_depart')}
            margin="normal"
            variant="outlined"
            width="100%"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CssGrid>
        <CssGrid item xs={12}>
          <Input
            id="operator"
            name="operator"
            label="Operator"
            select
            value={!_.isEmpty(tripDetails) && tripDetails[0].id_operator ? tripDetails[0].id_operator : ''}
            onChange={this.handleChange('id_operator')}
            margin="normal"
            variant="outlined"
            InputProps={{
              readOnly: tripDetails[0].id_operator === parseInt(userId, 10),
            }}
          >
            {collaborators.map(option => (
              <MenuItem key={option.id_conseiller} value={option.id_conseiller}>
                {`${option.firstname} ${option.lastname}`}
              </MenuItem>
            ))}
          </Input>
        </CssGrid>
        <CssGrid item xs={12}>
          <Input
            id="date"
            label="Creation"
            disabled
            value={!_.isEmpty(tripDetails) && tripDetails[0].date_creation ? tripDetails[0].date_creation : ''}
            margin="normal"
            variant="outlined"
          />
        </CssGrid>
        {!_.isEmpty(tripDetails) && !tripDetails[0].date_modif
          ? null
          : (
            <CssGrid item xs={12}>
              <Input
                id="date_modif"
                label="Modify"
                disabled
                value={!_.isEmpty(tripDetails) && tripDetails[0].date_modif ? tripDetails[0].date_modif : ''}
                margin="normal"
                variant="outlined"
              />
            </CssGrid>
          )
        }
        {/* cover image */}
        {
          ['standard', 'Horizon', 'standard2', 'Ciel'].includes(theme_devis)
            ? null
            : (
              <CssGrid item xs={12} className="image">
                <p className="imageTitle background-details">Cover image</p>
                <Uploader
                  imageDataArray={this.getImageUrl('cover_image')}
                  saveImage={(imagesDataArray, field) => this.saveImage(imagesDataArray, field)}
                  deleteImage={() => this.deleteImage('cover_image')}
                  redirectToGallery={() => this.redirectToGallery('cover_image')}
                  allowDrop={!!(tripDetails[0] && tripDetails[0].nom_devis)}
                  field="cover_image"
                />
              </CssGrid>
            )
        }
        {
         ['Ciel'].includes(theme_devis)
           ? (
             <CssGrid item xs={12} className="image">
               <p className="imageTitle background-details">Cover image</p>
               <Uploader
                 imageDataArray={this.getImageUrl('cover_image')}
                 saveImage={(imagesDataArray, field) => this.saveImage(imagesDataArray, field)}
                 deleteImage={() => this.deleteImage('cover_image')}
                 redirectToGallery={() => this.redirectToGallery('cover_image')}
                 allowDrop={!!(tripDetails[0] && tripDetails[0].nom_devis)}
                 field="cover_image"
                 nbMaxImg="3"
                 inLine="inLine"
               />
             </CssGrid>
           ) : null
        }
        {/* interactive map waypoints */}
        <CssGrid item xs={12}>
          <DisplayLocalisationMap
            locationList={locationList}
            handleLocalisationChange={this.handleLocalisationChange('map_localisation')}
            localisationList={tripDetails[0]}
          />
          <div className="buttons-collection">
            <button
              type="submit"
              className="select-localisation-button"
              onClick={this.handleLocalisationChange('reset')}
            >
              copy localisation from route
            </button>
          </div>
        </CssGrid>

        {/* map image */}
        <CssGrid item xs={12} className="image">
          <p className="imageTitle background-details">{theme_devis !== 'evasion' ? 'Map image' : 'Cover image 2'}</p>
          <Uploader
            imageDataArray={this.getImageUrl('image')}
            saveImage={(imagesDataArray, field) => this.saveImage(imagesDataArray, field)}
            deleteImage={() => this.deleteImage('image')}
            redirectToGallery={() => this.redirectToGallery('image')}
            allowDrop={!!(tripDetails[0] && tripDetails[0].nom_devis)}
            field="image"
          />
        </CssGrid>
      </div>
    )
  }

  displayCommentPart = () => {
    const {
      tripDetails,
    } = this.state
    const {
      informationsContent,
    } = this.props
    const { theme_devis } = informationsContent
    return (
      <div className="elevationBox">
        <CssGrid item xs={12}>
          <div className="editorStyle">
            <p className="editorTitle background-details">General information</p>
            <TextEditor
              object={tripDetails[0]}
              value={!_.isEmpty(tripDetails[0].comment) ? tripDetails[0].comment : ''}
              handleEditorChange={this.handleEditorChange('comment')}
            />
            {theme_devis !== 'Horizon' && (
            <div className="buttons-collection">
              <button
                type="submit"
                className="select-localisation-button"
                onClick={this.handleDefaultChange('comment')}
              >
                load default general information
              </button>
            </div>
            )}
          </div>
        </CssGrid>
      </div>
    )
  }

  displayAuthorizationWarning = () => {
    const {
      userAccount,
    } = this.props
    const {
      tripDetails,
      userId,
    } = this.state
    return (
      <AllowedToChange
        userId={userId}
        profil={userAccount.profil}
        tripDetails={tripDetails}
      />
    )
  }
  // ************************************************
  // IMAGE
  // Upload and show image

  getImageUrl = (image) => {
    const {
      userAccount,
    } = this.props
    const { company } = userAccount
    const {
      tripDetails,
      newImageArray_image,
      newURLArray_image,
      newImageArray_cover_image,
      newURLArray_cover_image,
    } = this.state
    let imageToLoad = ''
    let newURLArray = []
    let newImageArray = []

    switch (image) {
      case 'image':
        imageToLoad = !_.isEmpty(tripDetails) ? tripDetails[0].image : ''
        newImageArray = newImageArray_image
        newURLArray = newURLArray_image
        break;
      case 'cover_image':
        imageToLoad = !_.isEmpty(tripDetails) ? tripDetails[0].cover_image : ''
        newImageArray = newImageArray_cover_image
        newURLArray = newURLArray_cover_image
        break;
      default:
        break;
    }

    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = imageToLoad ? imageToLoad.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray, field) => {
    this.setState({
      [`newImageArray_${field}`]: imagesDataArray.map(img => img.imageName),
      [`newURLArray_${field}`]: imagesDataArray.map(img => img.imageUrl),
      detailsHasChanged: true,
    });
  }

  deleteImage = (field) => {
    const { tripDetails } = this.state
    this.setState({
      tripDetails: [{
        ...tripDetails[0],
        [field]: '',
      }],
      [`newImageArray_${field}`]: [],
      [`newURLArray_${field}`]: [],
      detailsHasChanged: true,
    })
  }

  // ****************************************
  // Redirect
  redirectToGallery = (informationImage) => {
    const {
      tripDetails,
    } = this.state
    const {
      history,
    } = this.props
    history.push('gallery', {
      currentPage: 'tripDetails',
      isSelectionAction: true,
      nbMaxImg: 10,
      currentImg: tripDetails[0][`${informationImage}`] ? tripDetails[0][`${informationImage}`].split(',') : [],
      state: {
        item: tripDetails[0],
        informationImage: `${informationImage}`,
      },
    })
  }

  goToTravelerList = () => {
    this.setState({ isRedirected: true })
    this.handleClose()
    this.saveData('traveler-management')
  }

  // ********************************
  // Action on valid button or stepper

  // if navigation by stepper
  saveData = (dest) => {
    const {
      history,
      userAccount,
      idTripSelected,
    } = this.props
    const {
      isValidateAction,
      tripDetails,
      userId,
    } = this.state
    const isActionButtonDisabled = !isAllowed(userId, userAccount.profil, tripDetails) && idTripSelected
    if (isValidateAction || isActionButtonDisabled) {
      history.push(dest)
    } else {
      this.setState({
        navigateToDestination: dest,
      })
      this.checkRequiredInputs()
    }
  }

  // Control that all required input are well fiiled
  checkRequiredInputs = (action, versionNumber) => {
    const {
      tripDetails,
      newImageArray_image,
      newImageArray_cover_image,
    } = this.state
    this.setState({
      isValidateAction: true,
      isModalOpen2: false,
    })


    if (versionNumber && versionNumber.version && !_.isEmpty(versionNumber.version)) {
      this.setState({
        tripDetails: [{
          ...tripDetails[0],
          version: versionNumber.version,
        }],
      })
    }
    // Save new image
    if (!_.isEmpty(newImageArray_image)) {
      this.setState({
        tripDetails: [{
          ...tripDetails[0],
          image: newImageArray_image.join(),
        }],
      })
    }
    if (!_.isEmpty(newImageArray_cover_image)) {
      this.setState({
        tripDetails: [{
          ...tripDetails[0],
          cover_image: newImageArray_cover_image.join(),
        }],
      })
    }

    // check valid name
    if (tripDetails[0].nom_devis === '') {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
    } else {
      setTimeout(() => {
        if (tripDetails[0].nom_programme === '') {
          this.setState({
            tripDetails: [{
              ...tripDetails[0],
              nom_programme: tripDetails[0].nom_devis,
            }],
          })
        }
        this.chooseAction(action)
      }, 200)
    }
  }

  chooseAction = (action) => {
    const {
      customerHasChanged,
      detailsHasChanged,
      tripNameHasChanged,
      navigateToDestination,
      tripDetails,
      token,
    } = this.state
    const {
      history,
      idTripSelected,
      isDuplicatedTrip,
      PUT_TRIP_DETAILS,
      LOADER,
    } = this.props

    LOADER(true)
    scrollTo('main')
    // if no trip has been selected in trip page => create trip
    if (!idTripSelected) {
      // INSERT new trip after verification of customer id existence
      PUT_TRIP_DETAILS(token, tripDetails, 'new_trip')

      // If client change
    } else if (customerHasChanged) {
      // INSERT new trip after verification of customer id existence
      // LOADER(true)
      this.setState({
        action: action,
      })
      PUT_TRIP_DETAILS(token, tripDetails, 'customer')

    // if it is a duplicated trip OR if trip name has changed, only 'update' is done
    } else if (isDuplicatedTrip || tripNameHasChanged) {
      this.updateInformations()

      // Else if some other detail has changed
    } else if (detailsHasChanged || action === 'version') {
      // action = 'version' => INSERT Newtrip + version update
      // action = 'update' => UPDATE trip
      if (action === 'version') {
        this.createNewVersion()
      } else {
        this.updateInformations()
      }
      // without any change go to next destination
    } else {
      LOADER(false)
      history.push(navigateToDestination)
      // history.push(navigateToDestination, { isNewVersion })
    }
  }

  // ********************************
  // Modal
  handleOpen = (title) => {
    this.setState({
      modalType: title,
      modalTitle: title === 'version'
        ? 'do you want to updade the current version or create a new one?'
        : 'Do you want to fill traveler list now ?',
    });
    if (title === 'version') {
      this.setState({
        isModalOpen2: true,
      })
    }
    if (title === 'travelerList') {
      this.setState({
        isModalOpen: true,
      })
    }
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      isModalOpen2: false,
      isValidateAction: false,
    });
  };

  createNewVersion = () => {
    const {
      token,
      tripDetails,
    } = this.state
    const {
      PUT_TRIP_DETAILS,
    } = this.props
    PUT_TRIP_DETAILS(token, tripDetails, 'version')
  }

  updateInformations = () => {
    const {
      token,
      tripDetails,
    } = this.state
    const {
      PUT_TRIP_DETAILS,
    } = this.props

    PUT_TRIP_DETAILS(token, tripDetails, 'update')
  }

  render() {
    const {
      isModalOpen,
      isModalOpen2,
      modalType,
      modalTitle,
      validateError,
      errorMsg,
      validMsg,
      tripNameHasChanged,
      tripDetails,
      userId,
      detailsHasChanged,
    } = this.state
    const {
      isLoading,
      userAccount,
      idTripSelected,
    } = this.props

    const isActionButtonDisabled = !_.isEmpty(tripDetails) && !_.isEmpty(userAccount) ? !isAllowed(userId, userAccount.profil, tripDetails) : true

    const inputValue = [
      'version',
    ]


    return (
      <div id="main" className="container">
        <Template>
          <div className="requestContainer">
            <Stepper saveData={dest => this.saveData(dest)} />
            <div className="dividerStepper" />
            {/* general form */}
            <div className="container">
              <h2>TRIP DETAILS</h2>
              {idTripSelected && this.displayAuthorizationWarning()}
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <div className="containerRow">
                {/* main information */}
                <div className="columnTwo">
                  {this.displayTripPart()}
                  {this.displayCustomerPart()}
                  {this.displayOptionPart()}
                  {this.displayCommentPart()}
                </div>
                {/* general information  */}
                <div className="columnOne">
                  {this.displayInformationPart()}
                </div>
              </div>
            </div>
            <CssGrid
              container
              spacing={2}
              className="actionContainer"
            >
              <CssGrid item md={4} xs={12}>
                <ActionButton
                  label={detailsHasChanged ? 'Update' : 'Validate'}
                  onClick={() => this.checkRequiredInputs('update')}
                  disabled={isActionButtonDisabled && !!idTripSelected}
                />
              </CssGrid>
              <CssGrid item md={4} xs={12}>
                <ActionButton
                  label="Save and duplicate tour"
                  onClick={() => this.handleOpen('version')}
                  disabled={isActionButtonDisabled && !!idTripSelected}
                />
              </CssGrid>
            </CssGrid>
          </div>
          {/* Modal type 'version' is not used anymore */}
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={modalType === 'version' ? this.createNewVersion : this.handleClose}
            onResponseYes={modalType === 'version' ? this.updateInformations : this.goToTravelerList}
            type={modalType}
            title={modalTitle}
            simpleAction={tripNameHasChanged}
          />
          {isModalOpen2
      && (
      <ModalComponent
        isModalOpen={isModalOpen2}
        onClose={this.handleClose}
        onResponseNo={this.handleClose}
        // eslint-disable-next-line no-undef
        onResponseYes={participant => this.checkRequiredInputs('version', participant)}
        title="Please confirm the version Number and validate"
        disabled={false}
        modalContent="trip_activity"
        type="number"
        inputValue={inputValue}
        defaultAdult={tripDetails[0].version}
        defaultChildren={1}
        defaultSenior={1}
      />
      )
      }
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  tripDetails: state.api.tripDetails,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  companyContent: state.companyReducer.companyContent,
  tripAccomodation: state.api.tripAccomodation,
  putTripDetails: state.api.putTripDetails,
  idTripSelected: state.api.idTripSelected,
  isNewTrip: state.api.isNewTrip,
  isDuplicatedTrip: state.api.isDuplicatedTrip,
  isTripStepValidated: state.api.isTripStepValidated,
  statusList: state.api.statusList,
  userAccount: state.userAccountReducer.userAccount,
  informationsContent: state.informationsReducer.informationsContent,
  collaborators: state.collaboratorsReducer.collaborators,
  customerList: state.customerReducer.customerList,
  putRequest: state.requestReducer.putRequest,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),

})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_LIST_CIRCUIT: (token, dateTampon, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    filterAdvisor,
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  ID_TRIP_SELECT: (idTripSelected, isNewTrip, isDetailModified) => dispatch({
    type: 'ID_TRIP_SELECT',
    idTripSelected,
    isNewTrip,
    isDetailModified,
  }),
  GET_TRIP_BILLING: (token, idDevis) => dispatch({
    type: 'GET_TRIP_BILLING',
    token,
    idDevis,
  }),
  GET_TRIP_DETAILS: (token, idTripSelected) => dispatch({
    type: 'GET_TRIP_DETAILS',
    token,
    idTripSelected,
  }),
  PUT_TRIP_DETAILS: (token, tripDetails, action) => dispatch({
    type: 'PUT_TRIP_DETAILS',
    token,
    tripDetails,
    action,
  }),
  RESET_TRIP_DETAILS: () => dispatch({
    type: 'RESET_TRIP_DETAILS',
  }),
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  GET_TRIP_ALLDAYS: (daysList, idCircuit) => dispatch({
    type: 'GET_TRIP_ALLDAYS',
    daysList,
    idCircuit,
  }),
  GET_TRIP_ACCOMODATION: accomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION',
    accomodationList,
  }),
  ACTIVE_STEP: activeStep => dispatch({
    type: 'ACTIVE_STEP',
    activeStep,
  }),
  VALIDATE_STEP: (page, value) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
  }),
  ROW_TRIP_SELECT: allRows => dispatch({
    type: 'ROW_TRIP_SELECT',
    allRows,
  }),
  GET_REQUEST: token => dispatch({
    type: 'GET_REQUEST',
    token,
  }),
  PUT_REQUEST: (token, request) => dispatch({
    type: 'PUT_REQUEST',
    token,
    request,
  }),
  GET_TRIP_DEVIS_FINAL: (token, idDevis) => dispatch({
    type: 'GET_TRIP_DEVIS_FINAL',
    token,
    idDevis,
  }),
  RESET_REQUEST: () => dispatch({
    type: 'RESET_REQUEST',
  }),
  GET_CUSTOMER_LIST: userId => dispatch({
    type: 'GET_CUSTOMER_LIST',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TripDetails))
