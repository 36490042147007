import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import MUIDataTable from 'mui-datatables'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Geocode from 'react-geocode';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import tableIcons from '../../../components/utils/icons/icons'
import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
  AddButton,
} from '../../../components'
import { CssGrid, AntTabs } from '../../../configCSS'
import activitiesColumns from '../../../components/utils/table/activitiesColumns'


import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'


Geocode.setApiKey('AIzaSyBkscY7LCKWGDdUms2ZBenfb_kFaCmOnqo');

const {
  Edit,
} = tableIcons

const suppliersFormatRegExp = new RegExp(' ', 'g')

class Suppliers extends PureComponent {
  static propTypes = {
    GET_SUPPLIERS_LIST: PropTypes.func.isRequired,
    GET_ACTIVITY_LIST: PropTypes.func.isRequired,
    RESET_SUPPLIERS_CONTENT: PropTypes.func.isRequired,
    PUT_SUPPLIERS_CONTENT: PropTypes.func.isRequired,
    DELETE_SUPPLIERS_CONTENT: PropTypes.func.isRequired,
    DELETE_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    RESET_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    putSuppliersContent: PropTypes.object,
    deleteSuppliersContent: PropTypes.object,
    suppliersList: PropTypes.array,
    locationGrandList: PropTypes.array,
    locationList: PropTypes.array,
    history: PropTypes.object.isRequired,
    activityList: PropTypes.array,
    deleteActivityContent: PropTypes.object,
  }

  static defaultProps = {
    suppliersList: [],
    locationGrandList: [],
    locationList: [],
    putSuppliersContent: {},
    deleteSuppliersContent: {},
    deleteActivityContent: {},
    activityList: [],
  }

  constructor(props) {
    super(props);
    this.displayAutocompleteInput = this.displayAutocompleteInput.bind(this)
    this.displayAutocompleteLocationGrand = this.displayAutocompleteLocationGrand.bind(this)
    this.displayAutocompleteLocation = this.displayAutocompleteLocation.bind(this)
    this.displayListActivities = this.displayListActivities.bind(this)
    this.displayInput = this.displayInput.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItem = this.modifyItem.bind(this)
    this.validate = this.validate.bind(this)
    this.reload = this.reload.bind(this)
    this.createNewsuppliers = this.createNewsuppliers.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onChangeLocationGrand = this.onChangeLocationGrand.bind(this)
    this.onChangeLocation = this.onChangeLocation.bind(this)
    this.filterOptions = this.filterOptions.bind(this)
    this.getOptionLabel = this.getOptionLabel.bind(this)
    this.getOptionLabelLocationGrand = this.getOptionLabelLocationGrand.bind(this)
    this.getOptionLabelLocation = this.getOptionLabelLocation.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getModifiedsuppliers = this.getModifiedsuppliers.bind(this)
    this.updatesuppliers = this.updatesuppliers.bind(this)
    this.getOptionLabelActivityTheme = this.getOptionLabelActivityTheme.bind(this)
    this.onChangeTab = this.onChangeTab.bind(this)
    this.tabProps = this.tabProps.bind(this)
    this.state = {
      keyTab: 1,
      actionLabel: 'Create',
      suppliersList: [],
      isNewsuppliers: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      disabledActionBtn: true,
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      suppliersContent: {
        locationGrand: '',
        location: '',
        id_activity_theme: 2,
      },
      goBack: '',
      itemsGoBack: '',
      isNewActivityBack: '',
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      suppliersList,
      GET_SUPPLIERS_LIST,
      GET_ACTIVITY_LIST,
      activityList,
    } = this.props
    // check if user is logged

    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(suppliersList)) {
      this.setState({
        suppliersList: _.sortBy(suppliersList, o => o.supplier),
      })
    } else {
      GET_SUPPLIERS_LIST(userId)
    }

    // check activity list
    if (_.isEmpty(activityList)) {
      GET_ACTIVITY_LIST(userId)
    }
    if (history.location.state && history.location.state.goBack === 'activityFromSupplier') {
      const {
        itemsGoBack,
      } = history.location.state
      this.setState({
        suppliersContent: itemsGoBack,
      })
    }
    // When this page is activated from activity page, get allinformations
    if (history.location.state && history.location.state.goBack === 'activity') {
      const {
        item,
        activityContent,
        goBack,
        isNewActivityBack,
      } = history.location.state

      this.setState({
        suppliersContent: {
          id_supplier: null,
          supplier: item.title,
        },
        itemsGoBack: activityContent,
        isNewActivityBack,
        goBack,
        isNewsuppliers: true,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      suppliersContent,
      suppliersList: suppliersListOld,
      goBack,
      itemsGoBack,
      isNewActivityBack,
    } = prevState
    const {
      suppliersList,
      putSuppliersContent,
      deleteSuppliersContent,
      deleteActivityContent,
      history,
    } = nextProps
    // suppliers saved in DB -> get back updated list
    if (putSuppliersContent && putSuppliersContent.success) {
      if (goBack) {
        history.push(goBack, {
          currentPage: 'suppliers',
          itemsGoBack,
          isNewActivityBack,
          idSupplierNew: putSuppliersContent.id,
        })
      } else {
        return {
          suppliersContent: {
            ...suppliersContent,
            id_supplier: putSuppliersContent.id,
          },
          validMsg: 'the suppliers was successfully recorded !',
        }
      }
    }

    // suppliers delete in DB -> get back updated list
    if (deleteSuppliersContent && deleteSuppliersContent.success) {
      return {
        suppliersContent: '',
        validMsg: 'the day was successfully deleted !',
      }
    }

    // suppliers can not be delete in DB -> it is used yet in day or accomodation
    if (deleteSuppliersContent && !_.isEmpty(deleteSuppliersContent) && !deleteSuppliersContent.success) {
      return {
        validateError: true,
        errorMsg: deleteSuppliersContent.message,
      }
    }

    if (suppliersList !== suppliersListOld) {
      return {
        suppliersList: _.sortBy(suppliersList, o => o.supplier),
      }
    }

    // activity delete in DB -> get back updated list
    if (deleteActivityContent && deleteActivityContent.success) {
      return {
        validMsg: 'Activity was successfully deleted !',
      }
    }
    return null; // Aucune mise à jour d'état
  }

  componentDidUpdate() {
    const {
      userId,
    } = this.state
    const {
      putSuppliersContent,
      deleteSuppliersContent,
      deleteActivityContent,
      RESET_SUPPLIERS_CONTENT,
      GET_ACTIVITY_LIST,
      RESET_ACTIVITY_CONTENT,
    } = this.props;

    // suppliers saved in DB -> get back updated list
    if (putSuppliersContent && putSuppliersContent.success) {
      this.reload()
      this.resetValidMsg()
      this.handleClose()
    }

    // suppliers delete in DB -> get back updated list
    if (deleteSuppliersContent && deleteSuppliersContent.success) {
      this.reload()
      this.resetValidMsg()
    }
    // suppliers can not be delete in DB -> it is used yet in day or accomodation
    if (deleteSuppliersContent && !_.isEmpty(deleteSuppliersContent) && !deleteSuppliersContent.success) {
      RESET_SUPPLIERS_CONTENT()
    }
    // activity delete in DB -> get back updated list
    if (deleteActivityContent && deleteActivityContent.success) {
      GET_ACTIVITY_LIST(userId)
      this.resetValidMsg()
      RESET_ACTIVITY_CONTENT()
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_SUPPLIERS_CONTENT,
      GET_SUPPLIERS_LIST,
    } = this.props
    RESET_SUPPLIERS_CONTENT()
    GET_SUPPLIERS_LIST(userId)
    this.setState({ validateError: false })
  }

  // *******************************
  // On Input value change

  onChange = (event, newValue) => {
    const { suppliersList, suppliersContent } = this.state
    const preIDActivityTheme = suppliersContent.id_activity_theme


    this.setState({
      validateError: false,
    })
    if (typeof newValue === 'string') {
      // On press Enter
      const valueClean = _.upperCase(_.trim(newValue)).replace(suppliersFormatRegExp, '-')
      const valueExist = suppliersList.filter(d => d.supplier === valueClean)
      if (_.isEmpty(valueExist)) {
        this.setState({
          suppliersContent: {
            id_supplier: null,
            supplier: newValue,
            location: '',
            locationGrand: '',
            id_activity_theme: '',
          },
          disabledActionBtn: false,
          isNewsuppliers: true,
        });
      } else {
        this.setState({
          suppliersContent: valueExist[0],
          disabledActionBtn: true,
          isNewsuppliers: false,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      const valueClean = _.upperCase(_.trim(newValue.inputValue)).replace(suppliersFormatRegExp, '-')
      const isNew = _.findIndex(suppliersList, o => o.supplier === valueClean) < 0
      this.setState({
        suppliersContent: {
          id_supplier: null,
          supplier: newValue.inputValue,
          location: '',
          locationGrand: '',
          id_activity_theme: preIDActivityTheme,
        },
        disabledActionBtn: !isNew,
        isNewsuppliers: isNew,
      });
    } else if (!newValue) {
      // clear input location
      this.setState({
        suppliersContent: {
          id_supplier: null,
          location: '',
          locationGrand: '',
          activity_theme: '',
          id_activity_theme: '',
        },
      });
    } else {
      this.setState({
        suppliersContent: newValue,
        disabledActionBtn: true,
        isNewsuppliers: false,
      });
    }
  }

  onChangeLocationGrand = (event, value) => {
    const { suppliersContent } = this.state
    if (value) {
      this.setState({
        suppliersContent: {
          ...suppliersContent,
          id_lieu_grand: value.id_lieu_grand,
          locationGrand: value.locationGrand,
        },
        disabledActionBtn: false,
      })
    } else {
      this.setState({
        suppliersContent: {
          ...suppliersContent,
          id_lieu_grand: '',
          locationGrand: '',
          activity_theme: '',
        },
        disabledActionBtn: false,
      })
    }
  }

  onChangeLocation = (event, value) => {
    const { suppliersContent } = this.state
    if (value) {
      this.setState({
        suppliersContent: {
          ...suppliersContent,
          id_lieu: value.id_lieu,
          location: value.location,
        },
        disabledActionBtn: false,
      })
    } else {
      this.setState({
        suppliersContent: {
          ...suppliersContent,
          id_lieu: '',
          location: '',
        },
        disabledActionBtn: false,
      })
    }
  }


  filterOptions = (options, params) => {
    const { suppliersList, suppliersContent } = this.state
    const location = suppliersContent && suppliersContent.location ? suppliersContent.location : '';
    const locationGrand = suppliersContent && suppliersContent.locationGrand ? suppliersContent.locationGrand : '';
    const activityTheme = suppliersContent && suppliersContent.id_activity_theme ? suppliersContent.id_activity_theme : '';
    const listFiltered = suppliersList.filter((supplier) => {
      const locationMatch = (location === 'none' || location === '') || supplier.location === location;
      const regionMatch = (locationGrand === 'none' || locationGrand === '') || supplier.locationGrand === locationGrand;
      const activityThemeMatch = activityTheme === '' || supplier.id_activity_theme === activityTheme;

      return locationMatch && regionMatch && activityThemeMatch;
    });
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = listFiltered.filter(d => d.nom_hebergement === params.inputValue)

    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        supplier: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  }

  getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(suppliersFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.supplier) {
      return '';
    }
    // Regular option
    return option.supplier;
  }

  getOptionLabelLocationGrand = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(suppliersFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.locationGrand) {
      return '';
    }
    // Regular option
    return option && option.locationGrand;
  }

  getOptionLabelLocation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(suppliersFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.location) {
      return '';
    }
    // Regular option
    return option && option.location;
  }

  goToActivity = (data = {}) => {
    const { history } = this.props
    history.push('activity', { ...data })
  }

  getIdActivity = (rowsSelected) => {
    const {
      activityList,
    } = this.props
    const {
      suppliersContent,
    } = this.state
    const activityListFiltered = activityList.filter(aL => aL.id_supplier === suppliersContent.id_supplier)

    return activityListFiltered[rowsSelected.data[0].dataIndex].id_activity
  }

  editActivity = (rowsSelected) => {
    const idActivity = this.getIdActivity(rowsSelected)
    const {
      suppliersContent,
    } = this.state
    this.goToActivity({
      goBack: 'suppliers',
      idActivity,
      suppliersContent,
    })
  }

  deleteActivity = (rowsDeleted) => {
    const { token } = this.state
    const {
      DELETE_ACTIVITY_CONTENT,
    } = this.props
    const idActivity = this.getIdActivity(rowsDeleted)


    if (confirm('Do you really want to delete activity ?')) {
      DELETE_ACTIVITY_CONTENT(token, idActivity)
      // update state without travelers deleted
    }
  }

  // ************************* Display *************************************
  // custom tool bar when an activity is selected
  displayToolBarSelect = selectedRows => (
    <Toolbar>
      <Tooltip title="Modify">
        <IconButton
          aria-label="modify"
          onClick={() => this.editActivity(selectedRows)}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => this.deleteActivity(selectedRows)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )

  // custom tool bar
  displayToolBar = () => {
    const {
      suppliersContent,
    } = this.state
    return (
      <AddButton
        title="Add new activity"
        goTo={() => this.goToActivity({
          goBack: 'suppliers',
          suppliersContent,
        })}
      />
    )
  }

  displayListActivities = () => {
    const {
      activityList,
    } = this.props
    const {
      suppliersContent,
    } = this.state
    const activityListFiltered = suppliersContent && suppliersContent.id_supplier !== null ? activityList.filter(aL => aL.id_supplier === suppliersContent.id_supplier) : []
    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      pagination: true,
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      selectableRows: 'single',
      selectableRowsOnClick: true,
      print: true,
      download: true,
      downloadOptions: {
        filename: 'activities_list.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      searchOpen: true,
      // onRowsDelete: (rowsDeleted, data) => this.deleteDay(rowsDeleted, data),
      customToolbarSelect: (selectedRows, displayData) => this.displayToolBarSelect(selectedRows, displayData),
      customToolbar: displayData => this.displayToolBar(displayData),
    }

    return (
      <div>

        <div>
          <CssGrid
            container
            spacing={2}
          >
            <CssGrid item md={12} xs={12}>
              <MUIDataTable
                className="table"
                title="activities"
                data={activityListFiltered}
                columns={activitiesColumns}
                options={options}
              />
            </CssGrid>

          </CssGrid>
        </div>

      </div>
    )
  }


  // ***************************
  // Display input
  displayAutocompleteInput = (content, list) => {
    const {
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Autocomplete
            id="suppliers-field"
            value={content}
            onChange={this.onChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={list}
            getOptionLabel={this.getOptionLabel}
            renderOption={option => option.supplier}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label="suppliers"
                margin="normal"
                placeholder="Select a place or create a new one"
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.modifyItem}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      suppliersContent,
    } = this.state
    const supplierId = suppliersContent.id_supplier
    const { DELETE_SUPPLIERS_CONTENT } = this.props

    if (confirm('Do you really want to delete this suppliers ?')) {
      if (supplierId) {
        DELETE_SUPPLIERS_CONTENT(token, supplierId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: suppliersContent.supplier ? 'This suppliers do not exist.' : 'Please, select a suppliers.',
        })
      }
    }
  }

  // Modify title
  modifyItem = () => {
    const { suppliersContent } = this.state
    if (!suppliersContent || !suppliersContent.id_supplier) return null
    this.setState({
      isNewsuppliers: false,
    })
    this.handleOpen()
    return null
  }

    // save input change
    handleChange = field => (event) => {
      const { suppliersContent } = this.state
      this.setState({
        suppliersContent: {
          ...suppliersContent,
          [field]: event.target.value,
        },
        disabledActionBtn: false,
      })
    }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      // suppliersContent,
      isNewsuppliers,
      suppliersContent,
      token,
    } = this.state
    const {
      PUT_SUPPLIERS_CONTENT,
    } = this.props
    // Check all required inputs
    this.setState({
      disabledActionBtn: true,
    })
    if (!suppliersContent.supplier || !suppliersContent.id_activity_theme) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // Create if it's new
    if (isNewsuppliers) {
      this.createNewsuppliers(suppliersContent);
    } else {
      PUT_SUPPLIERS_CONTENT(token, suppliersContent, 'update')
    }
    return null
  }

  // **************************
  // Modal action

  // YES response : create new suppliers
  updatesuppliers = () => {
    const {
      token,
      suppliersContent,
    } = this.state
    const { PUT_SUPPLIERS_CONTENT } = this.props
    PUT_SUPPLIERS_CONTENT(token, suppliersContent, 'update')
    this.handleClose()
  }


  displayAutocompleteLocationGrand = (locationGrandList) => {
    const {
      suppliersContent,
      validateError,
    } = this.state
    return (
      <Autocomplete
        id="locationGrand-check"
        value={suppliersContent}
        options={locationGrandList}
        getOptionLabel={this.getOptionLabelLocationGrand}
        onChange={this.onChangeLocationGrand}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        renderOption={option => option.locationGrand}
        renderInput={params => (
          <Input
            {...params}
            label="province or region"
            margin="normal"
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    )
  }

  displayAutocompleteLocation = (locationList) => {
    const {
      suppliersContent,
      validateError,
    } = this.state
    return (
      <Autocomplete
        id="location-check"
        value={suppliersContent}
        options={locationList}
        getOptionLabel={this.getOptionLabelLocation}
        onChange={this.onChangeLocation}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        renderOption={option => option.location}
        renderInput={params => (
          <Input
            {...params}
            label="location"
            margin="normal"
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    )
  }

  getOptionLabelActivityTheme = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(suppliersFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.activity_theme) {
      return ''
    }
    // Regular option
    return option.activity_theme;
  }


  displayInput = () => {
    const {
      suppliersContent,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={6} xs={12}>
          <Input
            id="email"
            label="email"
            value={!_.isEmpty(suppliersContent) && suppliersContent.email ? suppliersContent.email : ''}
            onChange={this.handleChange('email')}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          />
        </CssGrid>
        <CssGrid item md={6} xs={12}>
          <Input
            id="phone"
            label="phone"
            value={!_.isEmpty(suppliersContent) && suppliersContent.phone ? suppliersContent.phone : ''}
            onChange={this.handleChange('phone')}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          />
        </CssGrid>
      </CssGrid>
    )
  }

    // TABS
    onChangeTab = (event, keyTab) => {
      const themeList = [
        { id_activity_theme: 1, activity_theme: 'Accomodation' },
        { id_activity_theme: 2, activity_theme: 'Food' },
        { id_activity_theme: 3, activity_theme: 'Transportation' },
        { id_activity_theme: 4, activity_theme: 'Activity' },
        { id_activity_theme: 5, activity_theme: 'Service guide' },
        { id_activity_theme: 6, activity_theme: 'Flight' },
        { id_activity_theme: 7, activity_theme: 'Extra' },
        { id_activity_theme: 8, activity_theme: 'Other' },
      ]

      const filteredactivity = themeList.filter(t => t.id_activity_theme === keyTab + 1)
      this.setState({
        keyTab,
        suppliersContent: {
          id_activity_theme: keyTab === 7 ? '' : filteredactivity[0].id_activity_theme,
          id_supplier: '',
          supplier: '',
          location: '',
          locationGrand: '',
        },
      })
    }

   tabProps = index => ({
     id: `tab-${index}`,
     'aria-controls': `tabpanel-${index}`,
   })


  createNewsuppliers = (suppliersContent) => {
    const {
      token,
      btnActionDisabled,

    } = this.state
    // suppliersClean
    const { PUT_SUPPLIERS_CONTENT } = this.props
    this.setState({
      btnActionDisabled: !btnActionDisabled,
    })
    PUT_SUPPLIERS_CONTENT(token, suppliersContent, 'create')
  }

  getModifiedsuppliers = (item) => {
    const { suppliersContent } = this.state
    this.setState({
      suppliersContent: {
        ...suppliersContent,
        supplier: _.upperCase(_.trim(item.activity)).replace(suppliersFormatRegExp, '-'),
      },
    }, () => this.updatesuppliers())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      actionLabel,
      disabledActionBtn,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      suppliersContent,
      suppliersList,
      keyTab,
    } = this.state
    const { locationGrandList, locationList } = this.props
    const location = suppliersContent && suppliersContent.location ? suppliersContent.location : '';
    const locationGrand = suppliersContent && suppliersContent.locationGrand ? suppliersContent.locationGrand : '';
    const activityTheme = suppliersContent && suppliersContent.id_activity_theme ? suppliersContent.id_activity_theme : '';
    const listFiltered = suppliersList.filter((supplier) => {
      const locationMatch = (location === 'none' || location === '') || supplier.location === location;
      const regionMatch = (locationGrand === 'none' || locationGrand === '') || supplier.locationGrand === locationGrand;
      const activityThemeMatch = activityTheme === '' || supplier.id_activity_theme === activityTheme;

      return locationMatch && regionMatch && activityThemeMatch;
    });
    const themeList = [
      { id_activity_theme: '', activity_theme: '' },
      { id_activity_theme: 1, activity_theme: 'Accomodation' },
      { id_activity_theme: 2, activity_theme: 'Food' },
      { id_activity_theme: 3, activity_theme: 'Transportation' },
      { id_activity_theme: 4, activity_theme: 'Activity' },
      { id_activity_theme: 5, activity_theme: 'Service guide' },
      { id_activity_theme: 6, activity_theme: 'Flight' },
      { id_activity_theme: 7, activity_theme: 'Extra' },
      { id_activity_theme: 8, activity_theme: 'Other' },

    ]
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>SUPPLIERS MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              <AppBar position="static" className="tab-bar">
                <AntTabs value={keyTab} onChange={this.onChangeTab}>
                  <Tab label="Accomodation" {...this.tabProps(1)} />
                  <Tab label="Food" {...this.tabProps(2)} />
                  <Tab label="Transportation" {...this.tabProps(3)} />
                  <Tab label="Activity" {...this.tabProps(4)} />
                  <Tab label="Service guide" {...this.tabProps(5)} />
                  <Tab label="Flight" {...this.tabProps(6)} />
                  <Tab label="Other" {...this.tabProps(7)} />
                </AntTabs>
              </AppBar>
              <CssGrid
                container
                spacing={2}
              >
                <CssGrid item md={4} xs={12}>
                  {this.displayAutocompleteLocationGrand(locationGrandList)}
                </CssGrid>
                <CssGrid item md={4} xs={12}>
                  {this.displayAutocompleteLocation(locationList)}
                </CssGrid>
                <CssGrid item md={4} xs={12}>
                  <Input
                    id="activity_theme"
                    label="supplier theme"
                    select
                    margin="normal"
                    variant="outlined"
                    value={suppliersContent ? suppliersContent.id_activity_theme : ''}
                    className="largeWidth"
                    onChange={this.handleChange('id_activity_theme')}
                    required
                    error={validateError ? (suppliersContent && suppliersContent.id_activity_theme === '') : false}
                  >
                    {themeList.map(theme => (
                      <MenuItem key={theme.id_activity_theme} value={theme.id_activity_theme}>
                        {theme.activity_theme}
                      </MenuItem>
                    ))}
                  </Input>
                </CssGrid>
              </CssGrid>
              {this.displayAutocompleteInput(suppliersContent, listFiltered)}
              {this.displayInput()}
              <div className="TitreDashBoard">Services for this supplier</div>
              <br />
              {this.displayListActivities()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!suppliersContent || disabledActionBtn}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedsuppliers(item)}
            disabled={false}
            title="Enter the new value for this suppliers"
            warning="This will update this suppliers in all quotes !"
            type="activity"
            modalContent="activity"
            inputValue={!_.isEmpty(suppliersContent) ? suppliersContent.supplier : ''}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  suppliersList: _.filter(state.suppliersReducer.suppliersList, l => l.id_supplier !== 1),
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  putSuppliersContent: state.suppliersReducer.putSuppliersContent,
  locationGrandList: _.filter(state.api.locationGrandList, l => l.id_lieu_grand !== 1),
  deleteSuppliersContent: state.suppliersReducer.deleteSuppliersContent,
  deleteActivityContent: state.activityReducer.deleteActivityContent,
  activityList: _.filter(state.activityReducer.activityList, aL => aL.active === '1'),
})

const mapDispatchToProps = dispatch => ({
  GET_SUPPLIERS_LIST: userId => dispatch({
    type: 'GET_SUPPLIERS_LIST',
    userId,
  }),
  RESET_SUPPLIERS_CONTENT: () => dispatch({
    type: 'RESET_SUPPLIERS_CONTENT',
  }),
  PUT_SUPPLIERS_CONTENT: (token, suppliersContent, action) => dispatch({
    type: 'PUT_SUPPLIERS_CONTENT',
    token,
    suppliersContent,
    action,
  }),
  DELETE_SUPPLIERS_CONTENT: (token, supplierId) => dispatch({
    type: 'DELETE_SUPPLIERS_CONTENT',
    token,
    supplierId,
  }),
  GET_ACTIVITY_LIST: userId => dispatch({
    type: 'GET_ACTIVITY_LIST',
    userId,
  }),
  DELETE_ACTIVITY_CONTENT: (token, activityId) => dispatch({
    type: 'DELETE_ACTIVITY_CONTENT',
    token,
    activityId,
  }),
  RESET_ACTIVITY_CONTENT: () => dispatch({
    type: 'RESET_ACTIVITY_CONTENT',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Suppliers))
