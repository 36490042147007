import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import _, { isEmpty } from 'lodash'
import Spreadsheet from 'react-spreadsheet'
import * as XLSX from 'xlsx';
import checked from '../../assets/images/checked.png'
import checked2x from '../../assets/images/checked@2x.png'
import checked3x from '../../assets/images/checked@3x.png'
import unchecked from '../../assets/images/unchecked.png'
import unchecked2x from '../../assets/images/unchecked@2x.png'
import unchecked3x from '../../assets/images/unchecked@3x.png'

import {
  Template,
  ActionButton,
  Stepper,
  Loader,
  DayLineBilling,
  AllowedToChange,
  DayLineAcco,
  Input,
  TextEditor,
  ModalComponent,
} from '../../components'

import {
  TableService,
} from './components'


import {
  DaySelect,
} from '../tourOperator/component'

import { CssGrid } from '../../configCSS'
import isAllowed from '../../components/utils/profilAuthorization'
import scrollTo from '../../components/utils/scrollTo/scrollTo'

class Billing extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    LOADER: PropTypes.func.isRequired,
    idTripSelected: PropTypes.number,
    tripDetails: PropTypes.array,
    tripRoom: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripContent: PropTypes.array,
    tripSeasonByAccomodation: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    GET_TRIP_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT2: PropTypes.func.isRequired,
    GET_TRIP_ROOM: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT2: PropTypes.func.isRequired,
    PUT_TRIP_PRICE_ACTIVITY: PropTypes.func.isRequired,
    putTripTravellerActivity: PropTypes.object,
    PUT_TRIP_TRAVELLER_ACTIVITY: PropTypes.func.isRequired,
    DELETE_TRIP_ACTIVITY: PropTypes.func.isRequired,
    RESET_DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    deleteTripActivity: PropTypes.object.isRequired,
    tripRoomOpt1: PropTypes.array,
    tripRoomOpt2: PropTypes.array,
    tripBilling: PropTypes.object,
    tripActivities: PropTypes.object,
    tripPension: PropTypes.array,
    userAccount: PropTypes.object,
    putTripBilling: PropTypes.object,
    putTripPriceActivity: PropTypes.object,
    putTripAccomodationActivity: PropTypes.object,
    isTripDetailsStepValidated: PropTypes.bool.isRequired,
    roomList: PropTypes.array,
    history: PropTypes.object.isRequired,
    GET_TRIP_BILLING: PropTypes.func.isRequired,
    GET_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    PUT_TRIP_BILLING: PropTypes.func.isRequired,
    PUT_TRIP_ACCOMODATION_ACTIVITY: PropTypes.func.isRequired,
    RESET_PUT_TRIP_BILLING: PropTypes.func.isRequired,
    RESET_PUT_TRIP_ACCOMODATION_ACTIVITY: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    GET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    seasonByActivityAll: PropTypes.array,
    putTripContentAcco: PropTypes.object,
    PUT_TRIP_CONTENT_ACCO: PropTypes.func.isRequired,
    RESET_PUT_TRIP_CONTENT_ACCO: PropTypes.func.isRequired,
    GET_TRIP_CONTENT: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
  }

  static defaultProps = {
    isLoading: true,
    idTripSelected: null,
    tripAllDays: [],
    tripAllDaysAccomodations: [],
    seasonByActivityAll: [],
    tripRoom: [],
    tripPension: [],
    tripDetails: [],
    tripContent: [],
    tripSeasonByAccomodation: [],
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripRoomOpt1: [],
    tripRoomOpt2: [],
    tripBilling: {},
    putTripPriceActivity: {},
    tripActivities: {},
    putTripBilling: null,
    putTripAccomodationActivity: null,
    putTripTravellerActivity: null,
    roomList: [],
    userAccount: {},
    putTripContentAcco: null,
    informationsContent: {},
  }

  constructor(props) {
    super(props);
    this.displayAuthorizationWarning = this.displayAuthorizationWarning.bind(this)
    this.getAllTripInformationLoad = this.getAllTripInformationLoad.bind(this)
    this.getAllTripInformationLoadAfterUpdate = this.getAllTripInformationLoadAfterUpdate.bind(this)
    this.displayPriceCalculation = this.displayPriceCalculation.bind(this)
    this.priceCalculation = this.priceCalculation.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.changePrice = this.changePrice.bind(this)
    this.validate = this.validate.bind(this)
    this.updateQueryStatusMsg = this.updateQueryStatusMsg.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.resetDisplay = this.resetDisplay.bind(this)
    this.updateItems = this.updateItems.bind(this)
    this.updateItemsAcco = this.updateItemsAcco.bind(this)
    this.updateItemsAccoOpt1 = this.updateItemsAccoOpt1.bind(this)
    this.updateItemsAccoOpt2 = this.updateItemsAccoOpt2.bind(this)
    this.isModalOpenInChildren = this.isModalOpenInChildren.bind(this)
    this.displayDisplayModalBilling = this.displayDisplayModalBilling.bind(this)
    this.displayDisplayModalDefaultTraveller = this.displayDisplayModalDefaultTraveller.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleOpenTraveller = this.handleOpenTraveller.bind(this)
    this.handleOpenModalTrav = this.handleOpenModalTrav.bind(this)
    this.reloadActivites = this.reloadActivites.bind(this)

    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isModalDisplayed: false,
      daySelected: null,
      dayIndexSelected: null,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
      isModalOpenBilling: false,
      isModalOpenDefaultTraveller: false,
      tripBillingEditor: {},
      tripBillingEditorCheck: [],
      displayActivity: false,
      displayPrice: false,
      displayFull: true,
      isActivityChanged: false,
      displayCalculation: false,
      billingContent: '',
      totalPrice: '',
      totalPriceDistance: '',
      totalPriceAccomodation: '',
      totalPriceGroup: '',
      totalPriceIndAdult: '',
      totalPriceIndChild: '',
      totalPriceIndSenior: '',
      dataCotationDisplay: '',
      dataCotationDisplayAdult: '',
      dataCotationDisplayIndividualChild: '',
      dataCotationDisplayIndividualSenior: '',
      dataCotationDisplayAcco: '',
      dataCotationDisplayGroup: '',
      dataCotationDisplayDistance: '',
      displayAcco: false,
      accoContent: '',
      accoContentOpt1: '',
      accoContentOpt2: '',
      roomContent: '',
      roomContentOpt1: '',
      roomContentOpt2: '',
      nbRoom: '',
      nbRoomOpt1: '',
      nbRoomOpt2: '',
      itemToChange: {},
      daysToChange: 0,
      optToChange: 0,
      reasonOfChange: '',
      indexToChange: 0,
    };
  }

  componentDidMount() {
    const {
      history,
      LOADER,
      isTripDetailsStepValidated,
      tripBilling,
      tripContent,
      tripActivities,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      GET_TRIP_ACTIVITIES,
    } = this.props
    const { token } = this.state
    // check if user is logged

    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(tripContent)) {
      LOADER(true)
      this.getAllTripInformationLoad(tripContent)
    }

    if (_.isEmpty(tripActivities) && !_.isEmpty(tripContent)) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
    }
    // redirect if preview step is not active
    if (!isTripDetailsStepValidated) {
      history.push('trip')
    } else if (!_.isEmpty(tripBilling)) { // build table then fill it then build table price
      this.setState({
        tripBillingEditor: {
          tabPrice: tripBilling.tabPrice,
        },
      })
      this.setState({
        tripBillingEditorCheck: {
          displayTabCheck: tripBilling.displayTabCheck,
        },
      })
      this.setState({
        billingContent: tripBilling,
        accoContent: tripAccomodation,
        accoContentOpt1: tripAccomodationOpt1,
        accoContentOpt2: tripAccomodationOpt2,
        roomContent: tripRoom,
        roomContentOpt1: tripRoomOpt1,
        roomContentOpt2: tripRoomOpt2,
        nbRoom: tripContent[0].nb_chambre.split(','),
        nbRoomOpt1: tripContent[0].nb_chambre_opt1.split(','),
        nbRoomOpt2: tripContent[0].nb_chambre_opt2.split(','),
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      accoContent,
      accoContentOpt1,
      accoContentOpt2,
      roomContent,
      roomContentOpt1,
      roomContentOpt2,
      nbRoom,
      nbRoomOpt1,
      nbRoomOpt2,
    } = prevState
    const {
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      tripContent,
    } = nextProps
    if (tripAccomodation && isEmpty(accoContent)) {
      return {
        accoContent: tripAccomodation,
      }
    }
    if (tripAccomodationOpt1 && isEmpty(accoContentOpt1)) {
      return {
        accoContentOpt1: tripAccomodationOpt1,
      }
    }
    if (tripAccomodationOpt2 && isEmpty(accoContentOpt2)) {
      return {
        accoContentOpt2: tripAccomodationOpt2,
      }
    }
    if (tripRoom && isEmpty(roomContent)) {
      return {
        roomContent: tripRoom,
      }
    }
    if (tripRoomOpt1 && isEmpty(roomContentOpt1)) {
      return {
        roomContentOpt1: tripRoomOpt1,
      }
    }
    if (tripRoomOpt2 && isEmpty(roomContentOpt2)) {
      return {
        roomContentOpt2: tripRoomOpt2,
      }
    }
    if (tripContent && isEmpty(nbRoom)) {
      return {
        nbRoom: tripContent[0].nb_chambre.split(','),
      }
    }
    if (tripContent && isEmpty(nbRoomOpt1)) {
      return {
        nbRoomOpt1: tripContent[0].nb_chambre_opt1.split(','),
      }
    }
    if (tripContent && isEmpty(nbRoomOpt2)) {
      return {
        nbRoomOpt2: tripContent[0].nb_chambre_opt2.split(','),
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { token } = this.state
    const {
      LOADER,
      idTripSelected,
      putTripBilling,
      putTripAccomodationActivity,
      putTripTravellerActivity,
      putTripContentAcco,
      putTripPriceActivity,
      GET_TRIP_BILLING,
      RESET_PUT_TRIP_BILLING,
      GET_TRIP_DEVIS_FINAL,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripActivities,
      tripRoomOpt2,
      tripDetails,
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY,
      VALIDATE_STEP,
      GET_TRIP_ACTIVITIES,
      tripContent,
      RESET_PUT_TRIP_CONTENT_ACCO,
      RESET_DELETE_TRIP_ACTIVITIES,
      GET_TRIP_CONTENT,
      deleteTripActivity,
      isLoading,
    } = this.props
    const {
      tripContent: tripContentOld,
      tripActivities: tripActivitiesOld,
    } = prevProps
    if (putTripBilling) {
      this.updateQueryStatusMsg(putTripBilling.success, putTripBilling.queryStatusMsg)
      this.resetValidMsg()
      VALIDATE_STEP('Billing', true)
      RESET_PUT_TRIP_BILLING()
      // reset tripDevisFinal
      GET_TRIP_DEVIS_FINAL(token, tripDetails[0].id_devis)
      GET_TRIP_BILLING(token, idTripSelected)
    }
    if (putTripAccomodationActivity && putTripAccomodationActivity.success) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
    }
    if (putTripTravellerActivity && !_.isEmpty(putTripTravellerActivity) && 'success' in putTripTravellerActivity) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
      this.resetDisplay()
    }
    if (putTripPriceActivity && putTripPriceActivity.success) {
      LOADER(true)
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
    }
    if (!_.isEmpty(tripContent) && putTripContentAcco && putTripContentAcco.success) {
      LOADER(true)
      GET_TRIP_CONTENT(tripContent[0].id_circuit)
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_CONTENT_ACCO()
    }
    if (!_.isEqual(tripContent, tripContentOld)) {
      this.getAllTripInformationLoadAfterUpdate(tripContent)
      LOADER(false)
    }
    if (!_.isEmpty(deleteTripActivity) && 'success' in deleteTripActivity) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_DELETE_TRIP_ACTIVITIES()
    }


    if (!_.isEqual(tripActivities, tripActivitiesOld)) {
      this.resetDisplay()
    }

    // check if all informations have been received adn valid step
    if (isLoading && tripAccomodation && tripAccomodationOpt1 && tripAccomodationOpt2
        && tripRoom && tripRoomOpt1 && tripRoomOpt2
    ) {
      LOADER(false)
    }
  }

  componentWillUnmount() {
  }

  async getAllTripInformationLoad(content, message = false) {
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        if (_.isEmpty(tripAccomodation)) await GET_TRIP_ACCOMODATION(content[0].hebergement)
        if (_.isEmpty(tripAccomodationOpt1)) await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        if (_.isEmpty(tripAccomodationOpt2)) await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        if (_.isEmpty(tripRoom)) await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        if (_.isEmpty(tripRoomOpt1)) await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        if (_.isEmpty(tripRoomOpt2)) await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)


        if (message) {
          this.setState({
            isValidated: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

  async getAllTripInformationLoadAfterUpdate(content, message = false) {
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      LOADER,
    } = this.props
    // get trip informations
    LOADER(true)
    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        await GET_TRIP_ACCOMODATION(content[0].hebergement)
        await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)


        if (message) {
          this.setState({
            isValidated: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }


  // Message management **********************
  updateQueryStatusMsg = (isValid, msg) => {
    this.setState({
      validMsg: isValid ? msg : null,
      errorMsg: isValid ? null : msg,
      isValidated: true,
    })
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  resetDisplay =() => {
    this.setState({
      displayFull: true,
    })
  }

  reloadActivites =(e) => {
    const {
      GET_TRIP_ACTIVITIES,
      tripContent,
    } = this.props
    const {
      token,
    } = this.state
    e.preventDefault()
    this.setState({
      isActivityChanged: false,
      displayFull: false,
    })

    GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
  }

  isModalOpenInChildren = (state) => {
    this.setState({ isModalDisplayed: state })
  }

  sendDayIndexSelected = (rank) => {
    this.setState({
      dayIndexSelected: rank,
    })
  }

  sendDaySelected = (day) => {
    this.setState({
      daySelected: day,
    })
  }


 exportToExcel = (dataCotationDisplay) => {
   const {
     tripDetails,
   } = this.props
   const exelName = `${tripDetails[0].nom_devis}-datacotation.xlsx`
   const dataCotationDisplayAdultXLSX = dataCotationDisplay.map(row => row.map(cell => cell.value));
   const ws = XLSX.utils.aoa_to_sheet(dataCotationDisplayAdultXLSX);
   const wb = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
   const url = URL.createObjectURL(data);
   const a = document.createElement('a');
   a.href = url;
   a.download = exelName;
   a.click();
 };


  handleEditorChange = field => (content) => {
    this.setState({
      tripBillingEditor: {
        [field]: content,
      },
      isValidated: false,
    });
  }

    // save input change
    handleChange = field => (event) => {
      const { billingContent } = this.state
      const valueBenefit = field === 'benefit' ? event.target.value : billingContent.benefit
      const valueTripCost = field === 'tripCost' ? event.target.value : billingContent.tripCost
      const valueDiscountUnit = field === 'discountUnit' ? event.target.value : billingContent.discountUnit

      if (field.startsWith('exchangeRate')) {
        this.setState({
          totalPrice: '',
        })
      }
      if (field === 'benefit' || field === 'tripCost' || field === 'discountUnit') {
        const tripCost = parseFloat(valueTripCost);
        const benef = parseFloat(valueBenefit);
        const sellingPriceCalc = valueDiscountUnit === '%' ? tripCost + tripCost * benef / 100 : tripCost + benef;
        this.setState({
          billingContent: {
            ...billingContent,
            [field]: event.target.value,
            sellingPrice: sellingPriceCalc,
          },
        })
      } else {
        this.setState({
          billingContent: {
            ...billingContent,
            [field]: event.target.value,
          },
          isValidated: false,
        })
      }
    }

    handleDefaultChange = field => () => {
      const { informationsContent, userAccount } = this.props
      let source = ''
      switch (field) {
        case 'tabPrice':
          source = `${userAccount.language}_information_2_description`
          break;
        case 'tabPrice2':
          source = `${userAccount.language}_information_5_description`
          break;
        default:
          source = ''
      }
      this.setState({
        tripBillingEditor: {
          tabPrice: informationsContent[source],
        },
        isValidated: false,
      });
    }

    handleClose = () => {
      const { scrollY } = window;
      this.setState({
        isModalOpenBilling: false,
        isModalOpenDefaultTraveller: false,
      });
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 0);
    };

    changePrice = (priceChange) => {
      const {
        token,
      } = this.state
      const {
        PUT_TRIP_PRICE_ACTIVITY,
      } = this.props
      PUT_TRIP_PRICE_ACTIVITY(token, priceChange)
      this.setState({
        displayFull: false,
        totalPrice: '',
        totalPriceDistance: '',
        totalPriceAccomodation: '',
        totalPriceGroup: '',
        totalPriceIndAdult: '',
        totalPriceIndChild: '',
        totalPriceIndSenior: '',
        dataCotationDisplay: '',
        dataCotationDisplayAdult: '',
        dataCotationDisplayIndividualChild: '',
        dataCotationDisplayIndividualSenior: '',
        dataCotationDisplayAcco: '',
        dataCotationDisplayGroup: '',
        dataCotationDisplayDistance: '',
      });
    };

    deleteActivity = (actToDelete) => {
      const {
        DELETE_TRIP_ACTIVITY,
      } = this.props
      const {
        token,
      } = this.state
      DELETE_TRIP_ACTIVITY(token, actToDelete.id_act_cir)
      this.setState({
        displayActivity: false,
        displayFull: false,
      })
    }

    handleOpen = () => {
      const {
        daysToChange,
        itemToChange,
        accoContent,
        accoContentOpt2,
        accoContentOpt1,
        roomContent,
        roomContentOpt1,
        roomContentOpt2,
        nbRoom,
        nbRoomOpt1,
        nbRoomOpt2,
        optToChange,
        reasonOfChange,
        indexToChange,
      } = this.state
      if (reasonOfChange === 'accoChange') {
        if (optToChange === 0) {
          const updatedAccoContent = accoContent.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContent
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoom
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContent: updatedAccoContent,
            roomContent: updatedRoomContent,
            nbRoom: updatedNbRoomContent,
          });
        }
        if (optToChange === 1) {
          const updatedAccoContent = accoContentOpt1.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContentOpt1
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoomOpt1
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContentOpt1: updatedAccoContent,
            roomContentOpt1: updatedRoomContent,
            nbRoomOpt1: updatedNbRoomContent,
          });
        }
        if (optToChange === 2) {
          const updatedAccoContent = accoContentOpt2.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContentOpt2
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoomOpt2
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContentOpt2: updatedAccoContent,
            roomContentOpt2: updatedRoomContent,
            nbRoomOpt2: updatedNbRoomContent,
          });
        }
      }
      if (reasonOfChange === 'nbRoomChange') {
        const stringItem = _.join(itemToChange, '-')
        if (optToChange === 0) {
          const updatedRoomContent = roomContent
          const roomToChange = roomContent[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoom
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContent: updatedRoomContent,
            nbRoom: updatedNbRoomContent,
          });
        }
        if (optToChange === 1) {
          const updatedRoomContent = roomContentOpt1
          const roomToChange = roomContentOpt1[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoomOpt1
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContentOpt1: updatedRoomContent,
            nbRoomOpt1: updatedNbRoomContent,
          });
        }
        if (optToChange === 2) {
          const updatedRoomContent = roomContentOpt2
          const roomToChange = roomContentOpt2[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoomOpt2
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContentOpt2: updatedRoomContent,
            nbRoomOpt2: updatedNbRoomContent,
          });
        }
      }
      this.setState({
        isModalOpenBilling: false,
      });
    }

    displayDisplayModalBilling = () => {
      const {
        isModalOpenBilling,
      } = this.state
      return (
        <ModalComponent
          isModalOpen={isModalOpenBilling}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={this.handleOpen}
          title="Other days ends at the same location, do you also want to update for these location?"
        />
      )
    }

    handleOpenTraveller = () => {
      const {
        PUT_TRIP_TRAVELLER_ACTIVITY,
        tripDetails,
        tripActivities,
      } = this.props

      const {
        token,
      } = this.state

      const {
        nb_senior,
        nb_adulte,
        nb_enfant,
      } = tripDetails[0]
      PUT_TRIP_TRAVELLER_ACTIVITY(token, tripActivities, nb_senior, nb_adulte, nb_enfant)
      this.setState({
        isModalOpenDefaultTraveller: false,
        displayFull: false,
      });
    }

    handleOpenModalTrav = () => {
      this.setState({
        isModalOpenDefaultTraveller: true,
      })
    }

    displayDisplayModalDefaultTraveller = () => {
      const {
        isModalOpenDefaultTraveller,
      } = this.state
      return (
        <ModalComponent
          isModalOpen={isModalOpenDefaultTraveller}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={this.handleOpenTraveller}
          title="You will update all the activite with the travel number, are you sure?"
        />
      )
    }


  handleCheck = field => () => {
    const { tripBillingEditorCheck } = this.state

    this.setState({
      tripBillingEditorCheck: {
        ...tripBillingEditorCheck,
        [field]: tripBillingEditorCheck[field] === 1 ? 0 : 1,
      },
      isValidated: false,
    });
  }

  updateItems = (item) => {
    // replace item when update
    const {
      tripAllDays,
    } = this.props

    const indexItem = _.findIndex(tripAllDays, o => o.id_journee === item.id_journee)
    _.map(tripAllDays, (o, index) => {
      if (index === indexItem) {
        return item
      }
      return o
    })

    // this.setState({
    //   items: newItems,
    //   isValidated: false,
    // })
  }

  updateItemsAcco = (item, indexItemAcco, reason, nbOcc) => {
    // replace item when update
    const { accoContent, roomContent, nbRoom } = this.state
    if (reason === 'accoChange') {
      if (nbOcc.length > 1) {
        this.setState({
          isModalOpenBilling: true,
          isModalDisplayed: true,
          itemToChange: item,
          daysToChange: nbOcc,
          optToChange: 0,
          reasonOfChange: reason,
        })
      }
      if (item === null) {
        item = {
          id_hebergement: 1,
          nom_hebergement: 'none',
        }
      }
      const newItems = _.map(accoContent, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      const newItemsRoom = _.map(roomContent, (o, index) => {
        if (index === indexItemAcco) {
          return []
        }
        return o
      })
      const newItemsNbRoom = _.map(nbRoom, (o, index) => {
        if (index === indexItemAcco) {
          return '1'
        }
        return o
      })
      this.setState({
        isValidated: false,
        accoContent: newItems,
        roomContent: newItemsRoom,
        nbRoom: newItemsNbRoom,
      })
    }
    if (reason === 'roomChange') {
      const newItems = _.map(roomContent, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      this.setState({
        isValidated: false,
        roomContent: newItems,
      })
    }
    if (reason === 'nbRoomChange') {
    //  if (nbOcc.length > 1) {
    //    this.setState({
    //      isModalOpenBilling: true,
    //      isModalDisplayed: true,
    //      itemToChange: item,
    //      daysToChange: nbOcc,
    //      optToChange: 0,
      //     reasonOfChange: reason,
      //     indexToChange: indexItemAcco,
      //   })
      // }
      const stringItem = _.join(item, '-')
      const newItems = _.map(nbRoom, (o, index) => {
        if (index === indexItemAcco) {
          return stringItem
        }
        return o
      })
      this.setState({
        isValidated: false,
        nbRoom: newItems,
      })
    }
  }

  updateItemsAccoOpt1 = (item, indexItemAcco, reason, nbOcc) => {
    // replace item when update
    const { accoContentOpt1, roomContentOpt1, nbRoomOpt1 } = this.state
    if (reason === 'accoChange') {
      if (nbOcc.length > 1) {
        this.setState({
          isModalOpenBilling: true,
          isModalDisplayed: true,
          itemToChange: item,
          daysToChange: nbOcc,
          optToChange: 1,
          reasonOfChange: reason,
        })
      }
      if (item === null) {
        item = {
          id_hebergement: 1,
          nom_hebergement: 'none',
        }
      }
      const newItemsAcco = _.map(accoContentOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      const newItemsRoom = _.map(roomContentOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return []
        }
        return o
      })
      const newItemsNbRoom = _.map(nbRoomOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return '1'
        }
        return o
      })
      this.setState({
        isValidated: false,
        accoContentOpt1: newItemsAcco,
        roomContentOpt1: newItemsRoom,
        nbRoomOpt1: newItemsNbRoom,
      })
    }
    if (reason === 'roomChange') {
      const newItemsRoom = _.map(roomContentOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      this.setState({
        isValidated: false,
        roomContentOpt1: newItemsRoom,
      })
    }
    if (reason === 'nbRoomChange') {
    //  if (nbOcc.length > 1) {
      //   this.setState({
      //     isModalOpenBilling: true,
      //     isModalDisplayed: true,
      //     itemToChange: item,
      //     daysToChange: nbOcc,
      //     optToChange: 1,
      //     reasonOfChange: reason,
      //     indexToChange: indexItemAcco,
      //   })
      // }
      const stringItem = _.join(item, '-')
      const newItemsNb = _.map(nbRoomOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return stringItem
        }
        return o
      })
      this.setState({
        isValidated: false,
        nbRoomOpt1: newItemsNb,
      })
    }
  }

  updateItemsAccoOpt2 = (item, indexItemAcco, reason, nbOcc) => {
    // replace item when update
    const { accoContentOpt2, roomContentOpt2, nbRoomOpt2 } = this.state
    if (reason === 'accoChange') {
      if (nbOcc.length > 1) {
        this.setState({
          isModalOpenBilling: true,
          isModalDisplayed: true,
          itemToChange: item,
          daysToChange: nbOcc,
          optToChange: 2,
          reasonOfChange: reason,
        })
      }
      if (item === null) {
        item = {
          id_hebergement: 1,
          nom_hebergement: 'none',
        }
      }
      const newItems = _.map(accoContentOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      const newItemsRoom = _.map(roomContentOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return []
        }
        return o
      })
      const newItemsNbRoom = _.map(nbRoomOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return '1'
        }
        return o
      })
      this.setState({
        isValidated: false,
        accoContentOpt2: newItems,
        roomContentOpt2: newItemsRoom,
        nbRoomOpt2: newItemsNbRoom,
      })
    }
    if (reason === 'roomChange') {
      const newItems = _.map(roomContentOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      this.setState({
        isValidated: false,
        roomContentOpt2: newItems,
      })
    }
    if (reason === 'nbRoomChange') {
      //   if (nbOcc.length > 1) {
    //    this.setState({
      //    isModalOpenBilling: true,
      //    isModalDisplayed: true,
      //   itemToChange: item,
      //   daysToChange: nbOcc,
      //   optToChange: 2,
      //   reasonOfChange: reason,
      //  indexToChange: indexItemAcco,
      //  })
      // }
      const stringItem = _.join(item, '-')
      const newItems = _.map(nbRoomOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return stringItem
        }
        return o
      })
      this.setState({
        isValidated: false,
        nbRoomOpt2: newItems,
      })
    }
  }

  displayAuthorizationWarning = () => {
    const {
      tripDetails,
      userAccount,
    } = this.props
    const {
      userId,
    } = this.state
    return (
      <AllowedToChange
        userId={userId}
        profil={userAccount.profil}
        tripDetails={tripDetails}
      />
    )
  }

  priceCalculation = (e) => {
    e.preventDefault()
    const {
      billingContent,
    } = this.state
    const {
      tripDetails,
      tripAllDaysAccomodations,
      tripRoom,
      tripContent,
      tripPension,
      tripSeasonByAccomodation,
      roomList,
      tripActivities,
      seasonByActivityAll,
    } = this.props
    const tripActivitiesNoAccom = Object.keys(tripActivities).reduce((acc, key) => {
      acc[key] = tripActivities[key].filter(activity => activity.id_activity !== 75);
      return acc;
    }, {});
    const tripActivitiesGroup = Object.keys(tripActivitiesNoAccom).reduce((acc, key) => {
      acc[key] = tripActivitiesNoAccom[key].filter(activity => activity.activity_type === 'group');
      return acc;
    }, {});
    const tripActivitiesIndividual = Object.keys(tripActivitiesNoAccom).reduce((acc, key) => {
      acc[key] = tripActivitiesNoAccom[key].filter(activity => activity.activity_type === 'individual');
      return acc;
    }, {});

    const TabIncJour = tripAllDaysAccomodations ? tripAllDaysAccomodations.map(item => (item.day_duration)) : []
    const roomTypeArray = tripContent ? tripContent[0].type_chambre.split(',') : []
    const roomsNumberArray = tripContent && tripContent[0].nb_chambre ? tripContent[0].nb_chambre.split(',') : null
    // determinate the header, isolate the unique value on activity_theme AND currency
    const uniqueDistanceUnit = [...new Set(Object.values(tripAllDaysAccomodations)
      .flat()
      .map(item => item.distance_unit))];
    const uniqueThemeCurrencyActivity = Object.values(tripActivitiesNoAccom)
      .flat()
      .reduce((acc, item) => {
        if (item.activity_theme && item.activity_theme.trim() !== '') {
          const pair = `${item.activity_theme ? item.activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);

    uniqueThemeCurrencyActivity.push('Distance(KM)')
    if (uniqueDistanceUnit.includes('KM_MOUNTAIN')) {
      uniqueThemeCurrencyActivity.push('Distance(KM_MOUNTAIN)')
    }
    const uniqueThemeCurrencyDistance = ['Distance(KM)']
    if (uniqueDistanceUnit.includes('KM_MOUNTAIN')) {
      uniqueThemeCurrencyDistance.push('Distance(KM_MOUNTAIN)')
    }
    const uniqueCurrencies = [...new Set(Object.values(tripActivitiesNoAccom)
      .flat()
      .map(item => item.currency))];

    // determinate the header for the currency
    const tripAlldaysAccoNoNone = tripAllDaysAccomodations.filter(tAD => tAD.id_hebergement !== 1)
    const currencyHeb = tripAlldaysAccoNoNone.map(item => (item.currency))
    const currencyHebSansDoublons = _.uniq(currencyHeb);
    const uniqueCurrenciesHebAct = _.uniq(uniqueCurrencies.concat(currencyHebSansDoublons))
    uniqueCurrenciesHebAct.push('KM')
    if (uniqueDistanceUnit.includes('KM_MOUNTAIN')) {
      uniqueCurrenciesHebAct.push('KM_MOUNTAIN')
    }
    const nbOfRoomLabel = (i) => {
      const roomsTypeForDay = roomTypeArray[i] && roomTypeArray[i] !== '1' ? roomTypeArray[i].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[i] !== 'null' ? roomsNumberArray[i].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
      return label.join('')
    }
    // creation du tableau de cotation
    const dataCotation = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }, { value: 'Accomodation' }, { value: 'Room type' }, { value: 'Transport' }, { value: 'Guide' }, { value: 'Driver' }, { value: 'Pension' }],
    ]
    const dataCotationAcco = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }, { value: 'Accomodation' }, { value: 'Room type' }],
    ]
    const dataCotationSimple = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }],
    ]
    const newDataCotation = _.cloneDeep(dataCotation)
    const newDataCotationGroup = _.cloneDeep(dataCotationSimple)
    const newDataCotationAcco = _.cloneDeep(dataCotationAcco)
    const newDataCotationDistance = _.cloneDeep(dataCotationSimple)
    const newDataCotationIndividualAdult = _.cloneDeep(dataCotationSimple)
    const newDataCotationIndividualChild = _.cloneDeep(dataCotationSimple)
    const newDataCotationIndividualSenior = _.cloneDeep(dataCotationSimple)
    // ajout des valeurs propsée de currency dans l entete des colonne

    currencyHebSansDoublons.forEach((value) => {
      newDataCotation[0].push({ value: `accomodation (${value || 'no currency define'})` });
      newDataCotationAcco[0].push({ value: `accomodation (${value || 'no currency define'})` });
    });
    uniqueThemeCurrencyActivity.forEach((value) => {
      newDataCotation[0].push({ value: value });
    });
    const uniqueThemeCurrencyActivityGroup = Object.values(tripActivitiesGroup)
      .flat()
      .reduce((acc, item) => {
        if (item.activity_theme && item.activity_theme.trim() !== '') {
          const pair = `${item.activity_theme ? item.activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);

    uniqueThemeCurrencyActivityGroup.forEach((value) => {
      newDataCotationGroup[0].push({ value: value });
    });
    const uniqueThemeCurrencyActivityIndividual = Object.values(tripActivitiesIndividual)
      .flat()
      .reduce((acc, item) => {
        if (item.activity_theme && item.activity_theme.trim() !== '') {
          const pair = `${item.activity_theme ? item.activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);
    uniqueThemeCurrencyActivityIndividual.forEach((value) => {
      newDataCotationIndividualAdult[0].push({ value: value });
      newDataCotationIndividualChild[0].push({ value: value });
      newDataCotationIndividualSenior[0].push({ value: value });
    });

    uniqueDistanceUnit.forEach((value) => {
      newDataCotationDistance[0].push({ value: value });
    });
    let totalPrice1 = 0
    let totalPriceDistance = 0
    let totalPriceGroup = 0
    let totalPriceIndAdult = 0
    let totalPriceIndChild = 0
    let totalPriceIndSenior = 0
    let totalPriceAccomodation = 0
    _.map(tripAllDaysAccomodations, (o, index) => {
      const IncDay = _.sum(TabIncJour.slice(0, index))
      const activitiesOfDay = tripActivitiesNoAccom[index] ? [...tripActivitiesNoAccom[index]] : [];
      const activitiesOfDayGroup = tripActivitiesGroup[index] ? [...tripActivitiesGroup[index]] : [];
      if (activitiesOfDay) {
        activitiesOfDay.push({
          activity_theme: 'Distance',
          activity_type: 'group',
          currency: o.distance_unit && !_.isEmpty(o.distance_unit) ? o.distance_unit : 'KM',
          price_adultpeakseason: o.categorie,
        })
      }
      const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDay, 'd').format('dddd DD MMMM YYYY'))
        : ''
      const guideTab = tripContent[0].guide.split(',')
      const driverTab = tripContent[0].chauffeur.split(',')
      const PriceRoom = tripRoom[index]
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const seasonByAccomodation = tripSeasonByAccomodation.filter(c => c.id_hebergement === o.id_hebergement)
      let datejour1 = tripDetails[0].number_day_departure ? tripDetails[0].number_day_departure + IncDay : ''
      const annee = _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(index, 'days').format('YYYY'))
      const ily = ((annee % 4 === 0) && (annee % 100 !== 0)) || (annee % 400 === 0);
      let seasonName = ''
      let PriceRoomCalculated = 0
      let PriceRoomCalculatedForDisplay = ''

      // check pour annee bisextilz

      if (ily && (datejour1 > 59)) (datejour1 -= 1)
      // verif de la saison

      if (!_.isEmpty(seasonByAccomodation)) {
        seasonByAccomodation.map((sBA) => {
          if (datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
            seasonName = `price_${sBA.period_name}`
          }
          return null
        })
      }
      if (PriceRoom) {
        PriceRoom.map((PR, index2) => {
          if (PR[`${seasonName}`] && PR[`${seasonName}`] !== 0) {
            PriceRoomCalculated += (PR[`${seasonName}`] * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))
            PriceRoomCalculatedForDisplay = `${PriceRoomCalculatedForDisplay} + ${(PR[`${seasonName}`] * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))}`
          } else {
            PriceRoomCalculated += (PR.price_peakseason * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))
            PriceRoomCalculatedForDisplay = `${PriceRoomCalculatedForDisplay} + ${(PR.price_peakseason * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))}`
          }
          return null
        })
      }
      PriceRoomCalculatedForDisplay = PriceRoomCalculatedForDisplay.slice(3);
      // filter the activity accomodation
      const tripActivityAcco = tripActivities[index].filter(tA => tA.id_activity === 75)
      // check if a price have been define on the activity by day table if yes put this price
      PriceRoomCalculated = tripActivityAcco[0].final_price !== null ? tripActivityAcco[0].final_price : PriceRoomCalculated
      uniqueCurrenciesHebAct.map((uC, ic) => {
        if (uC === o.currency) {
          totalPrice1 += PriceRoomCalculated * billingContent[`exchangeRate${ic + 1}`]
          totalPriceAccomodation += PriceRoomCalculated * billingContent[`exchangeRate${ic + 1}`]
        }
        return null
      })
      // rajoute le prix en fonction de la currency

      const valueToAdd = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }, { value: o.nom_hebergement }, { value: nbOfRoomLabel(index) }, { value: o.transport }, { value: guideTab[index] }, { value: driverTab[index] }, { value: tripPension[index].pension }]
      const valueToAddAcco = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }, { value: o.nom_hebergement }, { value: nbOfRoomLabel(index) }]
      const valueToAddDistance = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]
      currencyHebSansDoublons.map((cHSD) => {
        if (o.currency === cHSD) {
          valueToAdd.push({ value: PriceRoomCalculated })
          valueToAddAcco.push({ value: tripActivityAcco[0].final_price !== null ? tripActivityAcco[0].final_price : PriceRoomCalculatedForDisplay })
        } else {
          valueToAdd.push({ value: '' })
          valueToAddAcco.push({ value: '' })
        }
        return null
      });
      // price management for activities ALL
      if (activitiesOfDay) {
        activitiesOfDay.map((aOD) => {
          const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === aOD.id_activity)
          let priceSeasonNameSenior = 0
          let priceSeasonNameAdult = 0
          let priceSeasonNameChild = 0
          let isSeasonFound = false;
          if (!_.isEmpty(seasonByActivities)) {
            seasonByActivities.map((sBA) => {
              if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
                priceSeasonNameSenior = `price_senior${sBA.period_name}`
                priceSeasonNameAdult = `price_adult${sBA.period_name}`
                priceSeasonNameChild = `price_child${sBA.period_name}`
                isSeasonFound = true;
              } else if (!isSeasonFound) {
                priceSeasonNameSenior = 'price_seniorpeakseason'
                priceSeasonNameAdult = 'price_adultpeakseason'
                priceSeasonNameChild = 'price_childpeakseason'
              }
              return null
            })
          } else {
            priceSeasonNameSenior = 'price_seniorpeakseason'
            priceSeasonNameAdult = 'price_adultpeakseason'
            priceSeasonNameChild = 'price_childpeakseason'
          }
          if (aOD.activity_type === 'individual') {
            aOD.priceCalculated = (aOD.final_price_senior != null ? aOD.final_price_senior : aOD[priceSeasonNameSenior] * aOD.nb_senior)
            + (aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * aOD.nb_adult)
            + (aOD.final_price_child !== null ? aOD.final_price_child : aOD[priceSeasonNameChild] * aOD.nb_child)
          } else {
            aOD.priceCalculated = aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * (aOD.nb_adult ? aOD.nb_adult : 1)
          }
          return (
            null
          )
        })
        // calculation of the price, regroup by theme and currency ALL
        const priceActivitiesOfDayThemeCurrencyConcat = activitiesOfDay.reduce((acc, cur) => {
          const key = cur.activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculated += cur.priceCalculated;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});


        const longPreviousColumn = valueToAdd.length
        const longPreviousColumnDistance = valueToAddDistance.length
        uniqueThemeCurrencyActivity.map(() => {
          valueToAdd.push({ value: '' })
          return null
        })
        uniqueDistanceUnit.map(() => {
          valueToAddDistance.push({ value: '' })
          return null
        })
        // ADD PRICING VALUES FOR ALL

        const activitiesOfDayConcat = Object.values(priceActivitiesOfDayThemeCurrencyConcat);
        if (activitiesOfDayConcat) {
          activitiesOfDayConcat.map((aOD) => {
            const currencyTheme = `${aOD.activity_theme ? aOD.activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivity.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAdd[indexTheme + longPreviousColumn].value = aOD.priceCalculated
              }
              return null
            })
            uniqueThemeCurrencyDistance.map((uTCD, indexTheme) => {
              if (uTCD === currencyTheme.trim()) {
                valueToAddDistance[indexTheme + longPreviousColumnDistance].value = aOD.priceCalculated
              }
              return null
            })
            if (aOD.activity_theme === 'Distance') {
              uniqueCurrenciesHebAct.map((uC, ic) => {
                if (uC === aOD.currency) { totalPriceDistance += aOD.priceCalculated * billingContent[`exchangeRate${ic + 1}`] }
                return null
              })
            }
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPrice1 += aOD.priceCalculated * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }
      }

      //* *************************************************************************************************
      // price management for activities GROUP


      const valueToAddGroup = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]

      if (activitiesOfDayGroup) {
        activitiesOfDayGroup.map((aOD) => {
          const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === aOD.id_activity)
          let priceSeasonNameSenior = 0
          let priceSeasonNameAdult = 0
          let priceSeasonNameChild = 0
          let isSeasonFound = false;
          if (!_.isEmpty(seasonByActivities)) {
            seasonByActivities.map((sBA) => {
              if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
                priceSeasonNameSenior = `price_senior${sBA.period_name}`
                priceSeasonNameAdult = `price_adult${sBA.period_name}`
                priceSeasonNameChild = `price_child${sBA.period_name}`
                isSeasonFound = true;
              } else if (!isSeasonFound) {
                priceSeasonNameSenior = 'price_seniorpeakseason'
                priceSeasonNameAdult = 'price_adultpeakseason'
                priceSeasonNameChild = 'price_childpeakseason'
              }
              return null
            })
          } else {
            priceSeasonNameSenior = 'price_seniorpeakseason'
            priceSeasonNameAdult = 'price_adultpeakseason'
            priceSeasonNameChild = 'price_childpeakseason'
          }
          if (aOD.activity_type === 'individual') {
            aOD.priceCalculated = (aOD.final_price_senior != null ? aOD.final_price_senior : aOD[priceSeasonNameSenior] * aOD.nb_senior)
            + (aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * aOD.nb_adult)
            + (aOD.final_price_child !== null ? aOD.final_price_child : aOD[priceSeasonNameChild] * aOD.nb_child)
          } else {
            aOD.priceCalculated = aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * (aOD.nb_adult ? aOD.nb_adult : 1)
          }
          return (
            null
          )
        })
        // calculation of the price, regroup by theme and currency
        const priceActivitiesOfDayThemeCurrencyConcatGroup = activitiesOfDayGroup.reduce((acc, cur) => {
          const key = cur.activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculated += cur.priceCalculated;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});
        const longPreviousColumn = valueToAddGroup.length
        uniqueThemeCurrencyActivityGroup.map(() => {
          valueToAddGroup.push({ value: '' })
          return null
        })
        // ADD PRICING VALUES FOR GROUP

        const activitiesOfDayConcatGroup = Object.values(priceActivitiesOfDayThemeCurrencyConcatGroup);
        if (activitiesOfDayConcatGroup) {
          activitiesOfDayConcatGroup.map((aOD) => {
            const currencyTheme = `${aOD.activity_theme ? aOD.activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityGroup.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddGroup[indexTheme + longPreviousColumn].value = aOD.priceCalculated
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceGroup += aOD.priceCalculated * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }
      }

      //* ************************************************************************************************ */
      // price management for activities INDIVIDUAL ADULT AND CHILD


      const valueToAddIndividualAdult = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]
      const valueToAddIndividualChild = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]
      const valueToAddIndividualSenior = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]

      const activitiesOfDayIndividual = tripActivitiesIndividual[index] ? [...tripActivitiesIndividual[index]] : [];
      if (activitiesOfDayIndividual) {
        activitiesOfDayIndividual.map((aOD) => {
          const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === aOD.id_activity)
          let priceSeasonNameSenior = 0
          let priceSeasonNameAdult = 0
          let priceSeasonNameChild = 0
          let isSeasonFound = false;
          if (!_.isEmpty(seasonByActivities)) {
            seasonByActivities.map((sBA) => {
              if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
                priceSeasonNameSenior = `price_senior${sBA.period_name}`
                priceSeasonNameAdult = `price_adult${sBA.period_name}`
                priceSeasonNameChild = `price_child${sBA.period_name}`
                isSeasonFound = true;
              } else if (!isSeasonFound) {
                priceSeasonNameSenior = 'price_seniorpeakseason'
                priceSeasonNameAdult = 'price_adultpeakseason'
                priceSeasonNameChild = 'price_childpeakseason'
              }
              return null
            })
          } else {
            priceSeasonNameSenior = 'price_seniorpeakseason'
            priceSeasonNameAdult = 'price_adultpeakseason'
            priceSeasonNameChild = 'price_childpeakseason'
          }
          if (aOD.activity_type === 'individual') {
            aOD.priceCalculatedSenior = aOD[priceSeasonNameSenior] * aOD.nb_senior
            aOD.priceCalculatedAdult = aOD[priceSeasonNameAdult] * aOD.nb_adult
            aOD.priceCalculatedChild = aOD[priceSeasonNameChild] * aOD.nb_child
          } else {
            aOD.priceCalculatedAdult = aOD[priceSeasonNameAdult] * (aOD.nb_adult ? aOD.nb_adult : 1)
          }
          return (
            null
          )
        })
        const longPreviousColumn = valueToAddIndividualAdult.length
        uniqueThemeCurrencyActivityIndividual.map(() => {
          valueToAddIndividualAdult.push({ value: '' })
          return null
        })
        // calculation of the price, regroup by theme and currency ADULT ONLY
        const priceActivitiesOfDayThemeCurrencyConcatAdultOnly = activitiesOfDayIndividual.reduce((acc, cur) => {
          const key = cur.activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculatedAdult += !_.isEmpty(cur.final_price) ? cur.priceCalculatedAdult : cur.final_price;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});

        // ADD PRICING VALUES FOR ADULT
        const activitiesOfDayConcatAdult = Object.values(priceActivitiesOfDayThemeCurrencyConcatAdultOnly);
        if (activitiesOfDayConcatAdult) {
          activitiesOfDayConcatAdult.map((aOD) => {
            const currencyTheme = `${aOD.activity_theme ? aOD.activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityIndividual.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddIndividualAdult[indexTheme + longPreviousColumn].value = aOD.priceCalculatedAdult
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceIndAdult += aOD.priceCalculatedAdult * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }

        // calculation of the price, regroup by theme and currency CHILD ONLY

        uniqueThemeCurrencyActivityIndividual.map(() => {
          valueToAddIndividualChild.push({ value: '' })
          return null
        })
        const priceActivitiesOfDayThemeCurrencyConcatChildOnly = activitiesOfDayIndividual.reduce((acc, cur) => {
          const key = cur.activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculatedChild += cur.priceCalculatedChild;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});
        // ADD PRICING VALUES FOR CHILD
        const activitiesOfDayConcatChild = Object.values(priceActivitiesOfDayThemeCurrencyConcatChildOnly);
        if (activitiesOfDayConcatChild) {
          activitiesOfDayConcatChild.map((aOD) => {
            const currencyTheme = `${aOD.activity_theme ? aOD.activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityIndividual.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddIndividualChild[indexTheme + longPreviousColumn].value = aOD.priceCalculatedChild
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceIndChild += aOD.priceCalculatedChild * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }

        // calculation of the price, regroup by theme and currency SENIOR ONLY

        uniqueThemeCurrencyActivityIndividual.map(() => {
          valueToAddIndividualSenior.push({ value: '' })
          return null
        })
        const priceActivitiesOfDayThemeCurrencyConcatSeniorOnly = activitiesOfDayIndividual.reduce((acc, cur) => {
          const key = cur.activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculatedSenior += cur.priceCalculatedSenior;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});
        // ADD PRICING VALUES FOR SENIOR
        const activitiesOfDayConcatSenior = Object.values(priceActivitiesOfDayThemeCurrencyConcatSeniorOnly);
        if (activitiesOfDayConcatSenior) {
          activitiesOfDayConcatSenior.map((aOD) => {
            const currencyTheme = `${aOD.activity_theme ? aOD.activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityIndividual.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddIndividualSenior[indexTheme + longPreviousColumn].value = aOD.priceCalculatedSenior
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceIndSenior += aOD.priceCalculatedSenior * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }
      }
      newDataCotationIndividualAdult.push(valueToAddIndividualAdult)
      newDataCotationIndividualChild.push(valueToAddIndividualChild)
      newDataCotationIndividualSenior.push(valueToAddIndividualSenior)
      newDataCotationGroup.push(valueToAddGroup)
      newDataCotationAcco.push(valueToAddAcco)
      newDataCotationDistance.push(valueToAddDistance)

      return (
        newDataCotation.push(valueToAdd)
      )
    })
    this.setState({
      totalPrice: totalPrice1,
      totalPriceDistance: totalPriceDistance,
      totalPriceAccomodation: totalPriceAccomodation,
      totalPriceGroup: totalPriceGroup,
      totalPriceIndAdult: totalPriceIndAdult,
      totalPriceIndChild: totalPriceIndChild,
      totalPriceIndSenior: totalPriceIndSenior,
      dataCotationDisplay: newDataCotation,
      dataCotationDisplayAdult: newDataCotationIndividualAdult,
      dataCotationDisplayIndividualChild: newDataCotationIndividualChild,
      dataCotationDisplayIndividualSenior: newDataCotationIndividualSenior,
      dataCotationDisplayAcco: newDataCotationAcco,
      dataCotationDisplayGroup: newDataCotationGroup,
      dataCotationDisplayDistance: newDataCotationDistance,
      isValidated: true,
    })
  }


  displayPriceCalculation = () => {
    const {
      billingContent,
      totalPrice,
      totalPriceDistance,
      totalPriceAccomodation,
      totalPriceIndAdult,
      totalPriceIndChild,
      totalPriceIndSenior,
      totalPriceGroup,
      dataCotationDisplay,
      dataCotationDisplayAdult,
      dataCotationDisplayIndividualChild,
      dataCotationDisplayIndividualSenior,
      dataCotationDisplayAcco,
      dataCotationDisplayDistance,
      dataCotationDisplayGroup,
    } = this.state
    const {
      tripAllDaysAccomodations,
      tripActivities,
    } = this.props
    // determinate the header, isolate the unique value on activity_theme AND currency
    const tripActivitiesNoAccom = Object.keys(tripActivities).reduce((acc, key) => {
      acc[key] = tripActivities[key].filter(activity => activity.id_activity !== 75);
      return acc;
    }, {});
    const uniqueThemeCurrencyActivity = Object.values(tripActivitiesNoAccom)
      .flat()
      .reduce((acc, item) => {
        if (item.activity_theme && item.activity_theme.trim() !== '') {
          const pair = `${item.activity_theme ? item.activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);
    const uniqueDistanceUnit = [...new Set(Object.values(tripAllDaysAccomodations)
      .flat()
      .map(item => item.distance_unit))];

    uniqueThemeCurrencyActivity.push('Distance(KM)')
    if (uniqueDistanceUnit.includes('KM_MOUNTAIN')) {
      uniqueThemeCurrencyActivity.push('Distance(KM_MOUNTAIN)')
    }
    const uniqueCurrencies = [...new Set(Object.values(tripActivitiesNoAccom)
      .flat()
      .map(item => item.currency))];
    // determinate the header for the currency
    const currencyHeb = tripAllDaysAccomodations.map(item => (item.currency))
    const currencyHebSansDoublons = _.uniq(currencyHeb);
    const uniqueCurrenciesHebAct = _.uniq(uniqueCurrencies.concat(currencyHebSansDoublons))
    uniqueCurrenciesHebAct.push('KM')
    if (uniqueDistanceUnit.includes('KM_MOUNTAIN')) {
      uniqueCurrenciesHebAct.push('KM_MOUNTAIN')
    }

    // creation du tableau de cotation
    const dataCotation = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }, { value: 'Accomodation' }, { value: 'Room type' }, { value: 'Transport' }, { value: 'Distance' }, { value: 'Guide' }, { value: 'Driver' }, { value: 'Pension' }],
    ]
    const newDataCotation = _.cloneDeep(dataCotation)
    // ajout des valeurs propsée de currency dans l entete des colonne

    currencyHebSansDoublons.forEach((value) => {
      newDataCotation[0].push({ value: `accomodation (${value || 'no currency define'})` });
    });
    uniqueThemeCurrencyActivity.forEach((value) => {
      newDataCotation[0].push({ value: value });
    });


    return (
      <div>
        <CssGrid
          container
          spacing={1}
        >
          <ActionButton
            label="Calculate"
            onClick={e => this.priceCalculation(e)}
            title="Launch price calculation"
          />
          <CssGrid className="currency" item md={3} xs={3}>
            <h2>Currency</h2>
            <Input
              id="currency"
              label="currency *"
              select
              value={!_.isEmpty(billingContent) && billingContent.currency ? billingContent.currency : ''}
              onChange={this.handleChange('currency')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            >
              <MenuItem value="$">$</MenuItem>
              <MenuItem value="€">€</MenuItem>
              <MenuItem value="VND">VND</MenuItem>
              <MenuItem value="THB">THB</MenuItem>
              <MenuItem value="CNY">CNY</MenuItem>
            </Input>
            {uniqueCurrenciesHebAct.map((item, index) => {
              const exchangeRate = `exchangeRate${index + 1}`
              return (
                <Input
                  id={`curencyRate${index}`}
                  key={item}
                  label={`exchange rate ${item} to ${billingContent.currency}`}
                  type="number"
                  value={!_.isEmpty(billingContent) && billingContent[exchangeRate] ? billingContent[exchangeRate] : ''}
                  onChange={this.handleChange(exchangeRate)}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                  required
                  error={billingContent[exchangeRate] === null || billingContent[exchangeRate] === undefined}
                />
              )
            })}
          </CssGrid>
          <CssGrid item md={2} xs={2}>
            <h2>Price decomposition</h2>
            Total trip cost calculated is : {totalPrice.toLocaleString()} {billingContent.currency}
            <Input
              id="Tripcost"
              label="trip cost"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.tripCost ? billingContent.tripCost : ''}
              onChange={this.handleChange('tripCost')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="unitBenefit"
              label="benefit unit"
              select
              margin="normal"
              variant="outlined"
              className="largeWidth"
              value={!_.isEmpty(billingContent) && billingContent.discountUnit ? billingContent.discountUnit : ''}
              onChange={this.handleChange('discountUnit')}
            >
              <MenuItem value="%">%</MenuItem>
              <MenuItem value={billingContent.currency}>{billingContent.currency}</MenuItem>
            </Input>
            <Input
              id="benefit"
              label="benefit"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.benefit ? billingContent.benefit : ''}
              onChange={this.handleChange('benefit')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="sellingPrice"
              label="selling price"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.sellingPrice ? billingContent.sellingPrice : ''}
              onChange={this.handleChange('sellingPrice')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="pricePerPerson"
              label="price by pax"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.pricePerPerson ? billingContent.pricePerPerson : ''}
              onChange={this.handleChange('pricePerPerson')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="deposit"
              label="deposit"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.deposit ? billingContent.deposit : ''}
              onChange={this.handleChange('deposit')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="soldToPAy"
              label="sold to pay (selling price - deposit)"
              value={!_.isEmpty(billingContent) && billingContent.sellingPrice ? billingContent.sellingPrice - billingContent.deposit : ''}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
          </CssGrid>
          <CssGrid item md={5} xs={5}>
            <Input
              id="Comments"
              label="Comments"
              value={!_.isEmpty(billingContent) && billingContent.comment ? billingContent.comment : ''}
              onChange={this.handleChange('comment')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
              multiline // ajout de la propriété multiline
              rows={6}
            />
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table ALL  - cost calculated for ALL is : {totalPrice.toLocaleString()} {billingContent.currency} </h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplay}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplay)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table GROUP - cost calculated for activity type GROUP is : {totalPriceGroup.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayGroup}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayGroup)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table INDIVIDUAL ADULT A - cost calculated for child activity individual  is : {totalPriceIndSenior.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayIndividualSenior}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayIndividualSenior)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table INDIVIDUAL ADULT B - cost calculated for adult activity individual  is : {totalPriceIndAdult.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayAdult}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayAdult)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table INDIVIDUAL CHILD - cost calculated for child activity individual  is : {totalPriceIndChild.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayIndividualChild}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayIndividualChild)}
              />
            </div>
          </CssGrid>

          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table ACCOMODATION - cost calculated is : {totalPriceAccomodation.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayAcco}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayAcco)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table DISTANCE - cost calculated is : {totalPriceDistance.toLocaleString()} {billingContent.currency} </h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayDistance}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayDistance)}
              />
            </div>
          </CssGrid>
        </CssGrid>
      </div>
    )
  }


  // Validate **********************
  validate = (e) => {
    e.preventDefault()
    const {
      token,
      tripBillingEditor,
      tripBillingEditorCheck,
      billingContent,
      accoContent,
      accoContentOpt1,
      accoContentOpt2,
      roomContent,
      roomContentOpt1,
      roomContentOpt2,
      nbRoom,
      nbRoomOpt1,
      nbRoomOpt2,
    } = this.state
    const {
      idTripSelected,
      tripBilling,
      tripActivities,
      PUT_TRIP_ACCOMODATION_ACTIVITY,
      PUT_TRIP_BILLING,
      PUT_TRIP_CONTENT_ACCO,
      tripAllDaysAccomodations,
      tripContent,
    } = this.props
    const dayIndex = tripAllDaysAccomodations.map(objet => ({
      dayIndex: objet.dayIndex,
      id_journee: objet.id_journee,
    }));
    const tableauAcco = []
    const tableauRoom = []
    const tableauNbRoom = []
    tableauAcco.push(accoContent.map(objet => (objet.id_hebergement ? objet.id_hebergement : '1')))
    tableauAcco.push(accoContentOpt1.map(objet => (objet.id_hebergement ? objet.id_hebergement : '1')))
    tableauAcco.push(accoContentOpt2.map(objet => (objet.id_hebergement ? objet.id_hebergement : '1')))
    tableauRoom.push(roomContent.map((roomByDay) => {
      if (roomByDay.length === 0) {
        return 1; // Mettre la valeur 1 si le tableau est vide
      }
      return roomByDay.map(objet => objet.id_heb_ch).join('-');
      // Utiliser join pour séparer les valeurs par '-'
    }))
    tableauRoom.push(roomContentOpt1.map((roomByDay) => {
      if (roomByDay.length === 0) {
        return 1; // Mettre la valeur 1 si le tableau est vide
      }
      return roomByDay.map(objet => objet.id_heb_ch).join('-');
      // Utiliser join pour séparer les valeurs par '-'
    }))
    tableauRoom.push(roomContentOpt2.map((roomByDay) => {
      if (roomByDay.length === 0) {
        return 1; // Mettre la valeur 1 si le tableau est vide
      }
      return roomByDay.map(objet => objet.id_heb_ch).join('-');
      // Utiliser join pour séparer les valeurs par '-'
    }))

    tableauNbRoom.push(nbRoom)
    tableauNbRoom.push(nbRoomOpt1)
    tableauNbRoom.push(nbRoomOpt2)
    // Copie de l'objet tripactivities en conservant uniquement les valeurs day_index et id_activity
    const copiedObject = _.cloneDeep(tripActivities);

    // Parcourir les tableaux copiés et supprimer les autres clés
    const tripActivitiesFiltered = _.mapValues(copiedObject, array => array.map(({ day_index, id_activity, id_accomodation }) => ({ day_index, id_activity, id_accomodation })));
    scrollTo('main')
    const billingDetails = !_.isEmpty(billingContent) ? billingContent : {}
    billingDetails.id_billing = _.isEmpty(tripBilling) ? null : tripBilling.id_billing;
    billingDetails.tabPrice = !_.isEmpty(tripBillingEditor) ? tripBillingEditor.tabPrice : ''
    billingDetails.displayTabCheck = !_.isEmpty(tripBillingEditorCheck) ? tripBillingEditorCheck.displayTabCheck : 0
    PUT_TRIP_BILLING(token, idTripSelected, billingDetails)
    PUT_TRIP_CONTENT_ACCO(token, tripContent[0].id_circuit, tableauAcco, tableauRoom, tableauNbRoom)
    PUT_TRIP_ACCOMODATION_ACTIVITY(token, dayIndex, tripActivitiesFiltered, idTripSelected, tableauAcco[0])
  }


  // *********************************************************

  render() {
    const {
      userId,
      validMsg,
      errorMsg,
      isValidated,
      tripBillingEditor,
      tripBillingEditorCheck,
      displayActivity,
      displayPrice,
      displayFull,
      displayAcco,
      displayCalculation,
      accoContent,
      accoContentOpt1,
      accoContentOpt2,
      roomContent,
      roomContentOpt1,
      roomContentOpt2,
      nbRoom,
      nbRoomOpt1,
      nbRoomOpt2,
      isModalDisplayed,
      isModalOpenBilling,
      isModalOpenDefaultTraveller,
      dayIndexSelected,
      daySelected,
      isActivityChanged,
    } = this.state
    const {
      isLoading,
      userAccount,
      tripDetails,
      tripAllDays,
      tripActivities,
      tripContent,
      informationsContent,
      tripAccomodation,
      tripRoom,
      tripSeasonByAccomodation,
      roomList,
      tripAllDaysAccomodations,
      seasonByActivityAll,
    } = this.props
    const { theme_devis } = informationsContent
    // fit table when resizing window
    const isActionButtonDisabled = !isActivityChanged && !_.isEmpty(tripDetails) && !_.isEmpty(userAccount) ? !isAllowed(userId, userAccount.profil, tripDetails) : true
    const FondDefautIndividual = {
      backgroundColor: '#CB8562',
    };
    const FondDefautGroup = {
      backgroundColor: '#CBC062',
    };
    const classContainer = (isLoading || !displayFull) ? 'container2' : 'container'
    return (
      <div id="main" className={classContainer}>
        <Template>
          <div className="requestContainer">
            <Stepper saveData={dest => this.saveData(dest)} />
            <div className="dividerStepper" />
            {isLoading ? <Loader /> : <div className="loader-null" />}
            <div className={classContainer}>
              <CssGrid
                container
                spacing={1}
              >
                <CssGrid item md={6} xs={6}>
                  <h2>Price to display</h2>
                  <div className="editorStyle">
                    <p className="editorTitle background-details">Description</p>
                    <TextEditor
                      object={tripBillingEditor}
                      value={tripBillingEditor.tabPrice}
                      handleEditorChange={this.handleEditorChange('tabPrice')}
                    />
                    <CssGrid
                      container
                      spacing={1}
                    >
                      <CssGrid item md={4} xs={4}>
                        <div className="buttons-collection">
                          <button
                            type="submit"
                            className="select-localisation-button"
                            onClick={this.handleDefaultChange('tabPrice')}
                          >
                            load default price tab
                          </button>
                        </div>
                      </CssGrid>
                      {(theme_devis === 'exploration2' || theme_devis === 'parfum2') && (
                      <CssGrid item md={4} xs={4}>
                        <div className="buttons-collection">
                          <button
                            type="submit"
                            className="select-localisation-button"
                            onClick={this.handleDefaultChange('tabPrice2')}
                          >
                            load default price tab 2
                          </button>
                        </div>
                      </CssGrid>
                      )}
                    </CssGrid>
                  </div>
                </CssGrid>
                <CssGrid item md={12} xs={12} className="deposit-item-checkbox" onClick={this.handleCheck('displayTabCheck')}>
                  <h3>  display price on the program:  &nbsp;</h3>
                  {tripBillingEditorCheck.displayTabCheck === 1
                    ? (
                      <img className="imgChecked" src={checked} srcSet={`${checked2x} 2x, ${checked3x} 3x`} alt="Checked" />
                    )
                    : (
                      <img className="imgChecked" src={unchecked} srcSet={`${unchecked2x} 2x, ${unchecked3x} 3x`} alt="Checked" />
                    )}
                </CssGrid>
                <CssGrid item md={12} xs={12} className="deposit-item-checkbox">
                  <h2>Accomodation&nbsp;   </h2>
                  <Switch
                    onChange={() => this.setState({ displayAcco: !displayAcco })}
                    label="display"
                    color="primary"
                  />
                </CssGrid>
                <CssGrid item md={4} xs={12}>
                  {displayAcco
                    ? (
                      <div className="dayLineBilling-container">
                        <div className="billingTitleCenter">
                          Accomodation Option 1
                        </div>
                        {accoContent && roomContent && accoContent.map((item, index) => (


                          <DayLineAcco
                             // eslint-disable-next-line react/no-array-index-key
                            key={`cleacco${item.id_hebergement}${item.nom_hebergement}${index}`}
                            item={item}
                            room={roomContent && roomContent[index]}
                            nbRoom={nbRoom && nbRoom[index].split('-')}
                            index={index}
                            updateItemsAcco={(newItem, newIndex, reason, nbOcc) => this.updateItemsAcco(newItem, newIndex, reason, nbOcc)}
                            isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                          />
                        ))}
                      </div>
                    ) : (null)}
                </CssGrid>
                <CssGrid item md={4} xs={12}>
                  {displayAcco
                    ? (
                      <div className="dayLineBilling-container">
                        <div className="billingTitleCenter">
                          Accomodation Option 2
                        </div>
                        {accoContentOpt1 && roomContentOpt1 && accoContentOpt1.map((item, index) => (
                          <DayLineAcco
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cleacco${item.id_hebergement}${item.nom_hebergement}${index}`}
                            item={item}
                            room={roomContentOpt1 && roomContentOpt1[index]}
                            nbRoom={nbRoomOpt1 && nbRoomOpt1[index].split('-')}
                            index={index}
                            updateItemsAcco={(newItem, newIndex, reason, nbOcc) => this.updateItemsAccoOpt1(newItem, newIndex, reason, nbOcc)}
                            isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                          />
                        ))}
                      </div>
                    ) : (null)}
                </CssGrid>
                <CssGrid item md={4} xs={12}>
                  {displayAcco
                    ? (
                      <div className="dayLineBilling-container">
                        <div className="billingTitleCenter">
                          Accomodation Option 3
                        </div>
                        {accoContentOpt2 && roomContentOpt2 && accoContentOpt2.map((item, index) => (
                          <DayLineAcco
                             // eslint-disable-next-line react/no-array-index-key
                            key={`cleacco${item.id_hebergement}${item.nom_hebergement}${index}`}
                            item={item}
                            room={roomContentOpt2 && roomContentOpt2[index]}
                            nbRoom={nbRoomOpt2 && nbRoomOpt2[index].split('-')}
                            index={index}
                            updateItemsAcco={(newItem, newIndex, reason, nbOcc) => this.updateItemsAccoOpt2(newItem, newIndex, reason, nbOcc)}
                            isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                          />
                        ))}
                      </div>
                    ) : (null)}
                </CssGrid>
                <CssGrid item md={12} xs={12} className="deposit-item-checkbox">
                  <h2>Activity and services &nbsp;   </h2>
                  <Switch
                    checked={displayActivity}
                    onChange={() => this.setState({ displayActivity: !displayActivity })}
                    label="display"
                    color="primary"
                  />
                </CssGrid>
                <CssGrid item md={12} xs={12}>
                  {displayActivity
                    ? (
                      <div className="dayLineBilling-container">
                        <DaySelect
                          dayIndexSelected={dayIndexSelected || 0}
                          daySelected={daySelected || tripAllDays[0]}
                          tripAllDays={tripAllDays}
                          tripContent={tripContent}
                          tripDetails={tripDetails}
                          sendDayIndexSelected={rank => this.sendDayIndexSelected(rank)}
                          sendDaySelected={day => this.sendDaySelected(day)}
                        />
                        <DayLineBilling
                          key="clejour"
                          item={daySelected || tripAllDays[0]}
                          index={dayIndexSelected || 0}
                          tripDetails={tripDetails[0]}
                          isValidated={isValidated}
                          updateItems={newItem => this.updateItems(newItem)}
                          redirectManagement={stateBack => this.redirectManagement(stateBack)}
                          isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                          upDateIsActivityChanged={change => this.setState({ isActivityChanged: change })}
                        />
                        <span style={FondDefautIndividual}>&nbsp;&nbsp; </span> <span>Individual</span><span style={FondDefautGroup}>&nbsp;&nbsp; </span> <span>Group</span>
                        <ActionButton
                          label="Load traveller number"
                          onClick={this.handleOpenModalTrav}
                          title="Update ALL INDIVIDUAL service and activities with traveller number of the program details"
                          disabled={_.isEmpty(tripDetails[0].nb_adulte)}
                        />
                      </div>
                    ) : (null)}

                </CssGrid>
                <CssGrid item md={12} xs={12} className="deposit-item-checkbox">
                  <h2>Price finalisation &nbsp;   </h2>
                  <Switch
                    onChange={() => this.setState({ displayPrice: !displayPrice })}
                    label="display"
                    color="primary"
                  />
                </CssGrid>
                <CssGrid item md={12} xs={12}>
                  {displayPrice
                    ? (
                      <div className="dayLineBilling-container">
                        <ActionButton
                          label="RELOAD"
                          onClick={e => this.reloadActivites(e)}
                        />
                        <DaySelect
                          dayIndexSelected={dayIndexSelected || 0}
                          daySelected={daySelected || tripAllDays[0]}
                          tripAllDays={tripAllDays}
                          tripContent={tripContent}
                          tripDetails={tripDetails}
                          sendDayIndexSelected={rank => this.sendDayIndexSelected(rank)}
                          sendDaySelected={day => this.sendDaySelected(day)}
                        />
                        <span style={FondDefautIndividual}>&nbsp;&nbsp; </span> <span>Individual</span><span style={FondDefautGroup}>&nbsp;&nbsp; </span> <span>Group</span>
                        <TableService
                          dayIndexSelected={dayIndexSelected || 0}
                          tripContent={tripContent}
                          tripAccomodation={tripAccomodation}
                          tripRoom={tripRoom}
                          tripDetails={tripDetails}
                          tripAllDays={tripAllDaysAccomodations}
                          activityListByCircuit={tripActivities[dayIndexSelected || 0]}
                          roomList={roomList}
                          tripSeasonByAccomodation={tripSeasonByAccomodation}
                          seasonByActivityAll={seasonByActivityAll}
                          nbOfDays={tripAllDaysAccomodations.length}
                          changePrice={priceChange => this.changePrice(priceChange)}
                          deleteActivity={actToDelete => this.deleteActivity(actToDelete)}
                        />
                      </div>

                    ) : (null)}
                </CssGrid>
                <CssGrid item md={12} xs={12} className="deposit-item-checkbox">
                  <h2>Price calculation &nbsp;   </h2>
                  <Switch
                    onChange={() => this.setState({ displayCalculation: !displayCalculation })}
                    label="display"
                    color="primary"
                  />
                </CssGrid>
              </CssGrid>
              {displayCalculation && this.displayPriceCalculation()}
              {isActionButtonDisabled
                ? (

                  <ActionButton
                    label="RELOAD DATA ACTIVTIES BEFORE SAVING"
                    onClick={e => this.reloadActivites(e)}
                  />
                )
                : null}
              {displayFull
                ? (
                  <ActionButton
                    label="Validate"
                    onClick={e => this.validate(e)}
                    disabled={isActionButtonDisabled}
                  />
                )
                : null}
              {this.displayAuthorizationWarning()}
              <div className="billingContainer">
                {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
                {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              </div>
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated && !isModalDisplayed}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
        {isModalOpenBilling && this.displayDisplayModalBilling()}
        {isModalOpenDefaultTraveller && this.displayDisplayModalDefaultTraveller()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  isTripDetailsStepValidated: state.api.isTripDetailsStepValidated,
  idTripSelected: state.api.idTripSelected,
  tripDetails: state.api.tripDetails,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  putTripTravellerActivity: state.billingReducer.putTripTravellerActivity,
  tripContent: state.api.tripContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripSeasonByAccomodation: state.api.tripSeasonByAccomodation,
  tripRoom: state.api.tripRoom,
  tripRoomOpt1: state.api.tripRoomOpt1,
  tripRoomOpt2: state.api.tripRoomOpt2,
  roomList: state.api.roomList,
  pensionList: state.api.pensionList,
  tripPrice: state.billingReducer.tripPrice,
  informationsContent: state.informationsReducer.informationsContent,
  tripPension: state.api.tripPension,
  tripBilling: state.billingReducer.tripBilling,
  tripActivities: state.billingReducer.tripActivities,
  putTripPriceActivity: state.billingReducer.putTripPriceActivity,
  putTripAccomodationActivity: state.billingReducer.putTripAccomodationActivity,
  putTripBilling: state.billingReducer.putTripBilling,
  userAccount: state.userAccountReducer.userAccount,
  putTripContentAcco: state.billingReducer.putTripContentAcco,
  deleteTripActivity: state.billingReducer.deleteTripActivity,
  seasonByAccomodation: state.api.seasonByAccomodation,
  seasonByActivityAll: state.api.seasonByActivityAll,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_TRIP_BILLING: (token, idDevis) => dispatch({
    type: 'GET_TRIP_BILLING',
    token,
    idDevis,
  }),
  DELETE_TRIP_ACTIVITY: (token, id_act_cir) => dispatch({
    type: 'DELETE_TRIP_ACTIVITY',
    token,
    id_act_cir,
  }),
  RESET_DELETE_TRIP_ACTIVITIES: () => dispatch({
    type: 'RESET_DELETE_TRIP_ACTIVITIES',
  }),
  PUT_TRIP_BILLING: (token, idDevis, billingDetails) => dispatch({
    type: 'PUT_TRIP_BILLING',
    token,
    idDevis,
    billingDetails,
  }),
  GET_TRIP_ACCOMODATION: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT1: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT1',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT2: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT2',
    tripAccomodationList,
  }),
  GET_TRIP_ROOM: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT1: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT1',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT2: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT2',
    tripRoomList,
    tripAccoList,
  }),
  RESET_PUT_TRIP_BILLING: () => dispatch({
    type: 'RESET_PUT_TRIP_BILLING',
  }),
  RESET_PUT_TRIP_CONTENT_ACCO: () => dispatch({
    type: 'RESET_PUT_TRIP_CONTENT_ACCO',
  }),
  GET_TRIP_DEVIS_FINAL: (token, idDevis) => dispatch({
    type: 'GET_TRIP_DEVIS_FINAL',
    token,
    idDevis,
  }),
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  VALIDATE_STEP: (page, value) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
  }),
  GET_TRIP_ACTIVITIES: (token, idCircuit) => dispatch({
    type: 'GET_TRIP_ACTIVITIES',
    token,
    idCircuit,
  }),
  RESET_PUT_TRIP_ACCOMODATION_ACTIVITY: () => dispatch({
    type: 'RESET_PUT_TRIP_ACCOMODATION_ACTIVITY',
  }),
  PUT_TRIP_ACCOMODATION_ACTIVITY: (token, tripAllDaysAccomodations, tripActivities, idTripSelected, idAcco) => dispatch({
    type: 'PUT_TRIP_ACCOMODATION_ACTIVITY',
    token,
    tripAllDaysAccomodations,
    tripActivities,
    idTripSelected,
    idAcco,
  }),
  PUT_TRIP_CONTENT_ACCO: (token, idCircuit, tableauAcco, tableauRoom, nbRoom) => dispatch({
    type: 'PUT_TRIP_CONTENT_ACCO',
    token,
    idCircuit,
    tableauAcco,
    tableauRoom,
    nbRoom,
  }),
  PUT_TRIP_PRICE_ACTIVITY: (token, priceChange) => dispatch({
    type: 'PUT_TRIP_PRICE_ACTIVITY',
    token,
    priceChange,
  }),
  PUT_TRIP_TRAVELLER_ACTIVITY: (token, tripActivities, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_TRAVELLER_ACTIVITY',
    token,
    tripActivities,
    nbSenior,
    nbAdult,
    nbChild,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing))
